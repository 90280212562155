<wh-s-modal *ngIf="isOpened" size="xs" [open]="isOpened" [position]="'absolute'" (modalCloseAction)="cancel.emit()">
  <ng-container class="wh-modal-title">
    <h1 class="wh-header-lg">{{ 'shared.ui.abort-application.title' | transloco }}</h1>
    <p class="wh-paragraph-md">{{ 'shared.ui.abort-application.description' | transloco }}</p>
  </ng-container>

  <ng-container class="wh-modal-body">
    <div class="dialog-content">
      <p class="wh-paragraph-md">
        {{ 'shared.ui.abort-application.dialog-content.description' | transloco }}
      </p>
      <wh-s-dropdown
        [label]="'shared.ui.abort-application.dialog-content.dropdown-label' | transloco"
        [items]="abortApplicationItems"
        [(ngModel)]="selectedReason"
      ></wh-s-dropdown>
      <wh-s-text-area
        [label]="'shared.ui.abort-application.dialog-content.text-area-label' | transloco"
        (inputChange)="setSelectedReasonExplanation($event)"
      ></wh-s-text-area>
    </div>
  </ng-container>

  <ng-container class="wh-modal-actions">
    <wh-s-button
      class="cancel-btn"
      size="md"
      variant="zero"
      [elevated]="false"
      [label]="'shared.ui.abort-application.dialog-actions.cancel' | transloco"
      (clickAction)="cancel.emit()"
    ></wh-s-button>

    <wh-s-button
      class="delete-btn"
      size="md"
      variant="primary"
      [elevated]="false"
      [label]="'shared.ui.abort-application.dialog-actions.confirm' | transloco"
      [disabled]="!selectedReason"
      [hasLoadingSpinner]="true"
      (clickAction)="onAbortApplication()"
    ></wh-s-button>
  </ng-container>
</wh-s-modal>
