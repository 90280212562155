import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TextVariantPipe } from '@web/web/shared/util/pipes';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
  exports: [TranslocoRootModule],
  imports: [CommonModule, TranslocoRootModule],
  providers: [TextVariantPipe],
})
export class WebCoreI18nModule {}
