import { AuthApi } from './auth-api.namespace';
import { HereMapsApi } from './here-maps.namespace';
import { TranslocoApi } from './transloco-api.namespace';
import { UtmApi } from './utm.namespace';

export namespace PromoterApi {
  export enum Role {
    PROMOTER = 'PROMOTER',
  }

  export interface Promoter {
    id: string;
    email: string;
    isBookmarked: boolean;
    firstName?: string;
    lastName?: string;
    communicationLanguage?: TranslocoApi.Locale;
    phone: string;
    createdAt?: Date;
  }

  export interface CreatePromoter {
    email: string;
    isBookmarked: boolean;
    password: string;
    phone?: string;
  }

  export interface EditPromoter extends CreatePromoter {
    id: string;
  }

  export interface PromoterLoginResponse extends AuthApi.BaseLoginResponseDto {
    role: Role;
    communicationLanguage: TranslocoApi.Locale;
    isBookmarked: boolean;
    avatarUrl?: string;
  }

  export interface RegisterPromoter {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    utm: UtmApi.UtmData;
    address: HereMapsApi.AddressZip;
    communicationLanguage: TranslocoApi.Locale;
  }
}
