import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthApi } from '@web/shared/data-access/model';
import { AuthApiService } from '@web/web/shared/data-access/api';
import { BehaviorSubject, Observable, catchError, map, of, take } from 'rxjs';
import { resetGlobalStoreData, setRole } from 'web/shared/data-access/global';
import { AuthLocalStorageService } from '../storage/auth-local-storage.service';

@Injectable()
export abstract class AuthViewModel<T> {
  public abstract readonly LOGOUT_ROUTE: string;
  public readonly isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly store = inject(Store);

  protected constructor(
    protected readonly authLocalStorageService: AuthLocalStorageService<T>,
    protected readonly router: Router,
    protected readonly authApiService: AuthApiService<T>,
  ) {
    this.init();
  }

  private init(): void {
    if (this.getLoginData()) {
      this.isAuthenticated$.next(true);

      return;
    }

    this.isAuthenticated$.next(false);
  }

  public storeLoginData(loginDto: T): void {
    this.authLocalStorageService.storeLoginData(loginDto);

    this.isAuthenticated$.next(true);
  }

  public logout(params?: Params): void {
    this.authLocalStorageService.clearData();

    this.isAuthenticated$.next(false);

    this.store.dispatch(resetGlobalStoreData());

    this.router.navigate([this.LOGOUT_ROUTE], { queryParamsHandling: 'merge', queryParams: params });
  }

  public getLoginData(): T | undefined {
    return this.authLocalStorageService.getLoginData();
  }

  public validateAuthAsObs(): Observable<boolean> {
    const loginData = this.getLoginData();

    //  1. If there is no data in local storage then do not even try to send validate token request
    if (!loginData) {
      return of(false);
    }

    //  TODO: Refactor abstraction in this class, make it so that it is aware of role field
    //        Extend common interface which accepts generic role type
    if (loginData && typeof loginData === 'object' && 'role' in loginData) {
      this.store.dispatch(setRole({ authRole: loginData.role as AuthApi.AUTH_ROLE }));
    }

    const tokenPair: AuthApi.TokenPair = {
      accessToken: (loginData as any).accessToken,
      refreshToken: (loginData as any).refreshToken,
    };

    return this.authApiService.validateToken(tokenPair).pipe(
      take(1),
      catchError(err => of(err)),
      map((payload: HttpErrorResponse | undefined) => !(payload instanceof HttpErrorResponse)),
    );
  }

  public clean(): void {
    this.authLocalStorageService.clearData();
    this.isAuthenticated$.next(false);
  }
}
