import { Injectable } from '@angular/core';
import { AuthApi, OtpApi } from '@web/shared/data-access/model';
import { AuthApiService } from '@web/web/shared/data-access/api';
import { Observable, first, take } from 'rxjs';

@Injectable()
export abstract class OtpViewModel<T> {
  protected abstract phone: string;

  protected constructor(private readonly authApiService: AuthApiService<T>) {}

  public setPhoneNumber(phone: string): void {
    this.phone = phone;
  }

  public createPending(): void {
    this.authApiService.createPending(this.phone).pipe(take(1)).subscribe();
  }

  public createPendingObs(): Observable<void> {
    return this.authApiService.createPending(this.phone);
  }

  public sendUserInputOtp(otp: OtpApi.OTPValidityCheck): Observable<void> {
    return this.authApiService.sendUserInputOtp(otp);
  }
}
