import { animate, style, transition, trigger } from '@angular/animations';

export const simpleSlideInOutAnimation = trigger('simpleSlideInOut', [
  transition(':enter', [
    // Slide in from the left
    style({ transform: 'translateX(100%)' }),
    animate('150ms ease-in', style({ transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    // Slide out to the right
    style({ display: 'none' }),
    animate('150ms ease-in', style({ transform: 'translateX(-100%)' })),
  ]),
]);
