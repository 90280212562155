import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { ButtonModule } from '@web/web/shared/ui/button';

@Component({
  selector: 'wh-s-account-verification',
  standalone: true,
  imports: [CommonModule, ButtonModule, RouterLink, WebCoreI18nModule],
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss'],
})
export class AccountVerificationComponent {
  @Input()
  public isContinueButtonDisabled: boolean;
}
