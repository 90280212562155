import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '@web/web/shared/ui/dropdown';
import { FeatherModule } from 'angular-feather';
import { LanguageSwitchComponent } from './language-switch.component';

@NgModule({
  imports: [CommonModule, DropdownModule, FeatherModule, FormsModule],
  declarations: [LanguageSwitchComponent],
  exports: [LanguageSwitchComponent],
})
export class LanguageSwitchModule {}
