export namespace CertificateApi {
  export interface Certificate {
    id?: string;
    certificateType: CertificateType;
    required?: boolean;
  }

  export interface CreateCertificate {
    certificateType: CertificateType;
    required?: boolean;
  }

  export enum CertificateType {
    KEY95 = 'KEY95',
    PASSENGER_TRANSPORT = 'PASSENGER_TRANSPORT',
    FORKLIFT_LICENSE = 'FORKLIFT_LICENSE',
  }

  // TODO: use translation labels instead of hardcoded values
  export function certificateTypeToReadableText(certificateType: CertificateType): string {
    switch (certificateType) {
      case CertificateApi.CertificateType.KEY95:
        return 'Key95';
      case CertificateApi.CertificateType.FORKLIFT_LICENSE:
        return 'Forklift License';
      case CertificateApi.CertificateType.PASSENGER_TRANSPORT:
        return 'Passenger Transport';
    }
  }
}
