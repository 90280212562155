<div [ngClass]="containerClasses" class="wh-s-text-area--container {{ isFocused ? 'focused' : '' }}">
  <!-- Animated label -->
  <div class="wh-s-text-area--label {{ _val ? 'stick-top' : '' }}">{{ label }}</div>

  <!-- Icon container (BEFORE text-area) -->
  <div class="wh-s-text-area-icon-before" (click)="iconClick.emit($event)">
    <i-feather *ngIf="variant === 'danger' && !isFocused" class="icon-before" name="x-octagon"></i-feather>
    <i-feather *ngIf="variant === 'success' && !isFocused" class="icon-before" name="check-circle"></i-feather>
    <i-feather *ngIf="disabled" class="icon-before" name="alert-octagon"></i-feather>
  </div>

  <!-- text-area -->
  <div class="text-area-wrapper">
    <textarea
      class="wh-s-text-area"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (input)="inputChange.emit(value)"
      (focusin)="isFocused = true"
      (focusout)="isFocused = false"
    ></textarea>
  </div>
</div>

<!-- Message wrapper -->
@if ((hint && !isFocused) || (variant === 'danger' && !isFocused)) {
  <div class="message-wrapper">
    <p *ngIf="hint && !isFocused" class="wh-s-text-area--hint">{{ hint }}</p>
    <p *ngIf="variant === 'danger' && !isFocused" class="wh-s-text-area--hint--danger">{{ errorLabel }}</p>
  </div>
}
