import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HereMapsApi } from '@web/shared/data-access/model';
import { Observable, of } from 'rxjs';
import { HERE_MAPS_API_KEY_TOKEN, HERE_MAPS_APP_ID_TOKEN } from './config/here-maps.token';

@Injectable({
  providedIn: 'root',
})
export class HereMapsApiService {
  private readonly urlGeocoderAutoSuggest = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
  private readonly autosuggestionLimit = 5;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translocoService: TranslocoService,
    @Inject(HERE_MAPS_API_KEY_TOKEN) private readonly API_KEY: string,
    @Inject(HERE_MAPS_APP_ID_TOKEN) private readonly APP_ID: string,
  ) {}

  public getSuggestions(location: string): Observable<HereMapsApi.AddressSuggestions> {
    const query = `query=${location}`;
    const limit = `limit=${this.autosuggestionLimit}`;
    const apiKey = `apiKey=${this.API_KEY}`;

    return this.httpClient.get<HereMapsApi.AddressSuggestions>(
      `${this.urlGeocoderAutoSuggest}?${query}&${limit}&${apiKey}`,
      {
        headers: new HttpHeaders().set('skipAuthorization', 'true'),
      },
    );
  }

  public getPositionData(location: string, forceTypes: boolean): Observable<HereMapsApi.Addresses> {
    if (!location) return of({ items: [] });
    const url = 'https://geocode.search.hereapi.com/v1/geocode';
    const query = `q=${location}`;
    const limit = `limit=${this.autosuggestionLimit}`;
    const types = 'types=address,city,houseNumber,postalCode,street';
    const apiKey = `apiKey=${this.API_KEY}`;

    return this.httpClient.get<HereMapsApi.Addresses>(
      `${url}?${query}${forceTypes ? `&${types}` : ''}&${limit}&${apiKey}&lang=${this.translocoService.getActiveLang().toLowerCase() ?? 'en'}`,
      {
        headers: new HttpHeaders().set('skipAuthorization', 'true'),
      },
    );
  }

  public getPositionDataViaZipCode(zipCode: string): Observable<HereMapsApi.Addresses> {
    if (!zipCode) return of({ items: [] });
    const url = 'https://geocode.search.hereapi.com/v1/geocode';
    const query = `qq=postalCode=${zipCode}`;
    const limit = `limit=${this.autosuggestionLimit}`;
    const apiKey = `apiKey=${this.API_KEY}`;

    return this.httpClient.get<HereMapsApi.Addresses>(`${url}?${query}&${limit}&${apiKey}`, {
      headers: new HttpHeaders().set('skipAuthorization', 'true'),
    });
  }
}
