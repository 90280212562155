import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobTypeApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class JobTypeApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/job-types`;
  }

  public getMany(pageSize: number, currentPage: number): Observable<PaginateResult<JobTypeApi.JobType>> {
    return this.httpClient.get<PaginateResult<JobTypeApi.JobType>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public getManyFromAdmin(
    pageSize: number,
    currentPage: number,
    searchTerm: string,
  ): Observable<PaginateResult<JobTypeApi.JobType>> {
    return this.httpClient.get<PaginateResult<JobTypeApi.JobType>>(`${this.url}/admin`, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public getAll(): Observable<JobTypeApi.JobType[]> {
    return this.httpClient.get<JobTypeApi.JobType[]>(`${this.url}/all`);
  }

  public getAllFromAdmin(): Observable<JobTypeApi.JobType[]> {
    return this.httpClient.get<JobTypeApi.JobType[]>(`${this.url}/all/admin`);
  }

  public findByQuery(searchTerm: string): Observable<JobTypeApi.JobType[]> {
    return this.httpClient.get<JobTypeApi.JobType[]>(`${this.url}/search`, { params: { searchTerm } });
  }

  public update(updateJobType: JobTypeApi.UpdateJobType): Observable<JobTypeApi.JobType> {
    return this.httpClient.put<JobTypeApi.JobType>(this.url, updateJobType);
  }

  public create(createJobTypeDto: JobTypeApi.CreateJobType): Observable<JobTypeApi.JobType> {
    return this.httpClient.post<JobTypeApi.JobType>(`${this.url}`, createJobTypeDto);
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
