<section class="auth-img-wrapper">
  <img src="/assets/image/auth-img.svg" alt="Logo image" loading="lazy" />
</section>

@defer {
  <ng-container *ngIf="externalApplicationViewModel.vm$ | async as vm">
    <section class="external-application-container">
      @if ((loadingViewModel.isLoading$ | async) && !vm.jobDetails?.titleTextVariants) {
        <wh-s-spinner />
      } @else {
        <div class="header">
          <img src="/assets/image/workerhero_logo.png" alt="Logo image" loading="lazy" />

          <wh-language-switch-new
            [languages]="(languageSwitchService.vm$ | async)?.languagesNew ?? []"
            (languageClicked)="switchLanguage($event)"
          />
        </div>
        <div class="details">
          <h3 class="wh-header-lg">{{ vm.jobDetails?.titleTextVariants | textVariant }}</h3>

          <wh-s-profile-detail-video
            *ngIf="vm.jobDetails?.jobBranding?.videoLink"
            [hasTitle]="false"
            [videoUrl]="videoLinkTransform(vm.jobDetails?.jobBranding?.videoLink ?? '')"
          />

          <wh-s-profile-detail-about [about]="vm.jobDetails?.jobBranding?.shortDescription | textVariant" />
        </div>

        <div class="actions">
          <wh-s-button
            class="apply"
            [elevated]="false"
            [disabled]="loadingViewModel.isLoading$ | async"
            [label]="'domain.jobs.feature.job-details.actions.apply-label' | transloco"
            (clickAction)="processApplication()"
          />

          <wh-s-button
            class="discard"
            [label]="'domain.jobs.feature.job-details.actions.cancel-label' | transloco"
            [elevated]="false"
            [disabled]="loadingViewModel.isLoading$ | async"
            variant="zero"
            (clickAction)="externalApplicationViewModel.toggleAbortDialog()"
          />
        </div>
      }
    </section>

    <wh-s-app-abort-application
      *ngIf="vm.abortDialogOpened"
      [isOpened]="vm.abortDialogOpened"
      (cancel)="externalApplicationViewModel.toggleAbortDialog()"
      (abortApplication)="externalApplicationViewModel.abortApplication($event)"
    />
  </ng-container>
} @loading {
  <wh-s-spinner />
}
