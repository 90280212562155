import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, switchMap, take, tap } from 'rxjs';
import { AuthViewModel } from '../viewmodel/auth.viewmodel';
import { LoginViewModel } from '../viewmodel/login.viewmodel';

@Injectable()
export abstract class AuthGuard<T> {
  protected constructor(
    protected readonly authViewModel: AuthViewModel<T>,
    protected readonly loginViewModel: LoginViewModel,
    protected readonly router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.loginViewModel.setRedirectUrl(state.url, route.queryParams);

    return this.authViewModel.validateAuthAsObs().pipe(
      take(1),
      switchMap(() =>
        this.authViewModel.isAuthenticated$.pipe(
          take(1),
          tap(isSuccess => {
            if (isSuccess) {
              return;
            }

            this.authViewModel.logout(route.queryParams);
          }),
        ),
      ),
    );
  }
}
