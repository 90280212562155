import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyApi, IntegrationApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) baseUrl: string,
  ) {
    this.url = `${baseUrl}/companies`;
  }

  public getMany(
    pageSize: number,
    currentPage: number,
    searchTerm: string,
  ): Observable<PaginateResult<CompanyApi.Company>> {
    return this.httpClient.get<PaginateResult<CompanyApi.Company>>(
      `${this.url}${searchTerm ? '?search-term=' + searchTerm : ''}`,
      {
        params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
      },
    );
  }

  public getAll(): Observable<CompanyApi.Company[]> {
    return this.httpClient.get<CompanyApi.Company[]>(`${this.url}/all`);
  }

  public getCompanyEmployer(): Observable<CompanyApi.Company> {
    return this.httpClient.get<CompanyApi.Company>(`${this.url}/employer`);
  }

  public getCompanyProfile(companyId: string): Observable<CompanyApi.Company> {
    return this.httpClient.get<CompanyApi.Company>(`${this.url}/profile/${companyId}`);
  }

  public getCompanyGeneralProfile(companyId: string): Observable<CompanyApi.General> {
    return this.httpClient.get<CompanyApi.General>(`${this.url}/profile/general/${companyId}`);
  }

  public getCompanyBranding(companyId: string): Observable<CompanyApi.Branding> {
    return this.httpClient.get<CompanyApi.Branding>(`${this.url}/profile/branding/${companyId}`);
  }

  public addAvatar(imageFile: File, companyId?: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', imageFile);

    if (!companyId) {
      return this.httpClient.post(`${this.url}/avatar`, formData, {
        responseType: 'text',
      });
    }

    return this.httpClient.post(`${this.url}/company/${companyId}/avatar`, formData, {
      responseType: 'text',
    });
  }

  public changeCompanyAvatar(imageFile: File, companyId: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', imageFile);

    return this.httpClient.post(`${this.url}/${companyId}/avatar`, formData, {
      responseType: 'text',
    });
  }

  public changeCompanyBanner(imageFile: File, companyId: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', imageFile);

    return this.httpClient.post(`${this.url}/${companyId}/banner`, formData, {
      responseType: 'text',
    });
  }

  public addBrandingImages(imageFiles: File[]): Observable<void> {
    const formData = new FormData();
    imageFiles.forEach(image => {
      formData.append('files', image, image.name);
    });

    return this.httpClient.post<void>(`${this.url}/branding-images`, formData);
  }

  public deleteAvatar(): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/avatar`);
  }

  public addBanner(imageFile: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', imageFile);

    return this.httpClient.post(`${this.url}/banner`, formData, {
      responseType: 'text',
    });
  }

  public deleteBanner(): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/banner`);
  }

  public create(createCompany: CompanyApi.CreateCompany): Observable<CompanyApi.Company> {
    return this.httpClient.post<CompanyApi.Company>(this.url, createCompany);
  }

  public update(updateCompany: CompanyApi.UpdateCompany): Observable<CompanyApi.Company> {
    return this.httpClient.put<CompanyApi.Company>(this.url, updateCompany);
  }

  public updateGeneral(companyId: string, updateGeneral: CompanyApi.General): Observable<CompanyApi.General> {
    return this.httpClient.put<CompanyApi.General>(`${this.url}/profile/general/${companyId}`, updateGeneral);
  }

  public updateBranding(companyId: string, brandingDto: CompanyApi.UpdateBranding): Observable<CompanyApi.Branding> {
    const formData = new FormData();

    if (brandingDto.description) {
      formData.append('description', JSON.stringify(brandingDto.description));
    }

    formData.append('videoLink', brandingDto?.videoLink ?? '');

    if (brandingDto.newImages?.length) {
      brandingDto.newImages.forEach(image => {
        formData.append('newImages', JSON.stringify({ isHeader: image.isHeader, name: image.name }));
        formData.append('files', image.file, image.name);
      });
    }

    if (brandingDto.idOfImagesToDelete?.length) {
      formData.append('idOfImagesToDelete', JSON.stringify(brandingDto.idOfImagesToDelete));
    }

    return this.httpClient.put<CompanyApi.Branding>(`${this.url}/profile/branding/${companyId}`, formData);
  }

  public delete(id: string | undefined): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  public deleteBrandingImage(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/branding-images/${id}`);
  }

  public getIntegrations(): Observable<IntegrationApi.KomboIntegration[]> {
    return this.httpClient.get<IntegrationApi.KomboIntegration[]>(`${this.url}/integration`);
  }

  public getIntegrationsByCompanyId(id: string): Observable<IntegrationApi.Integration[]> {
    return this.httpClient.get<IntegrationApi.Integration[]>(`${this.url}/integration/company/${id}`);
  }

  public getKomboJobs(companyId: string, searchTerm: string): Observable<IntegrationApi.KomboJobDropdown[]> {
    return this.httpClient.get<IntegrationApi.KomboJobDropdown[]>(
      `${this.url}/integration/jobs/${companyId}?searchTerm=${searchTerm}`,
    );
  }

  public initKomboEmbeddedFlow(): Observable<string> {
    return this.httpClient.get(`${this.url}/integration/init`, { responseType: 'text' });
  }

  public addIntegration(activationToken: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/integration`, { activationToken });
  }

  public addIntegrationByCompanyId(activationToken: string, companyId: string): Observable<IntegrationApi.Integration> {
    return this.httpClient.post<IntegrationApi.Integration>(`${this.url}/integration/company/${companyId}`, {
      activationToken,
    });
  }

  public deleteIntegration(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/integration/${id}`);
  }

  public getStatistics(companyId: string): Observable<CompanyApi.Statistics> {
    return this.httpClient.get<CompanyApi.Statistics>(`${this.url}/statistics`, {
      params: new HttpParams().set('companyId', companyId),
    });
  }
}
