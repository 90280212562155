<div class="profile-dropdown">
  <div class="dropdown-content">
    <a [routerLink]="['/profile']">
      <i-feather name="user" />
      <span>{{ 'shared.ui.profile-settings-dropdown.profile' | transloco }}</span>
    </a>
    <a [routerLink]="['/settings']">
      <i-feather name="settings" />
      <span>{{ 'shared.ui.profile-settings-dropdown.settings' | transloco }}</span>
    </a>
  </div>
</div>
