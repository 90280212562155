import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

export const slideInOutAnimation: AnimationTriggerMetadata = trigger('slideInOut', [
  // Transition for entering from the left
  transition('void => start-left', [
    style({ transform: 'translateX(-100%)', opacity: 0 }), // Start from the left
    animate('300ms ease-in', style({ transform: 'translateX(0)', opacity: 1 })), // Move to position
  ]),
  // Transition for entering from the right
  transition('void => start-right', [
    style({ transform: 'translateX(100%)', opacity: 0 }), // Start from the right
    animate('300ms ease-in', style({ transform: 'translateX(0)', opacity: 1 })), // Move to position
  ]),
  // Transition for leaving to the left
  transition('leave-left => void', [
    style({ transform: 'translateX(0)', opacity: 1, display: 'none' }), // Current position
    animate('300ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 })), // Leave to the left
  ]),
  // Transition for leaving to the right
  transition('leave-right => void', [
    style({ transform: 'translateX(0)', opacity: 1, display: 'none' }), // Current position
    animate('300ms ease-out', style({ transform: 'translateX(100%)', opacity: 0 })), // Leave to the right
  ]),
]);
