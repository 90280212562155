import { Injectable } from '@angular/core';
import { JoobleApiService } from '@web/web/shared/data-access/api';
import { catchError, of } from 'rxjs';

/**
 * https://docs.google.com/document/d/101L0FQPC3uk8dpWbnQpvKDO1Jjk5EfU2QVCBZSjDO8A/edit?tab=t.0
 */
@Injectable({ providedIn: 'root' })
export class JoobleService {
  public utm_away: string;

  constructor(private readonly joobleApiService: JoobleApiService) {}

  public sendJoobleClickValidityEvent(): void {
    if (!this.utm_away) {
      return;
    }

    this.joobleApiService
      .sendJoobleFeedbackEvent({
        utmAway: this.utm_away,
        actionType: 'InitPage',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendJoobleApplicationStartEvent(): void {
    if (!this.utm_away) {
      return;
    }

    this.joobleApiService
      .sendJoobleFeedbackEvent({
        utmAway: this.utm_away,
        actionType: 'ApplyStart',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendJoobleApplicationCompleteEvent(): void {
    if (!this.utm_away) {
      return;
    }

    this.joobleApiService
      .sendJoobleFeedbackEvent({
        utmAway: this.utm_away,
        actionType: 'ApplyFinish',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }
}
