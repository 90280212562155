import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobApi, PromotionApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class PromotionApiService {
  private readonly url: string;
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/promotions`;
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  public create(promotion: Partial<PromotionApi.Promotion>): Observable<void> {
    return this.httpClient.post<void>(`${this.url}`, promotion);
  }

  public edit(promotion: Partial<PromotionApi.Promotion>): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${promotion.id}`, promotion);
  }

  public getMany(pageSize: number, currentPage: number): Observable<PaginateResult<PromotionApi.Promotion>> {
    return this.httpClient.get<PaginateResult<PromotionApi.Promotion>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public getDetails(id: string, userId: string): Observable<PromotionApi.PromotionDetail> {
    return this.httpClient.get<PromotionApi.PromotionDetail>(`${this.url}/promotion/${id}`, {
      params: userId ? new HttpParams().set('userId', userId) : {},
    });
  }

  public getAllAssignedPromotionsToUser(): Observable<PromotionApi.Promotion[]> {
    return this.httpClient.get<PromotionApi.Promotion[]>(`${this.url}/all-assigned`);
  }

  public getAllAvailablePromotions(distance?: number): Observable<PromotionApi.Promotion[]> {
    return this.httpClient.get<PromotionApi.Promotion[]>(
      `${this.url}/all-available${distance ? '?distance=' + distance : ''}`,
    );
  }

  public assignPromotionToUser(promotionId: string): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/assign-promotion/${promotionId}`, {});
  }

  public sharePromotion(promotionId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/share-promotion/${promotionId}`, {});
  }

  public getJobList(): Observable<JobApi.JobList[]> {
    return this.httpClient.get<JobApi.JobList[]>(`${this.url}/job-list`);
  }
}
