import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HereMapsApi } from '@web/shared/data-access/model';

@Pipe({
  name: 'formatCompanyAddress',
  standalone: true,
})
export class FormattedCompanyAddressPipe implements PipeTransform {
  public transform(address: HereMapsApi.AddressData | undefined): string {
    if (!address) return '';

    return address.city !== 'Not applicable'
      ? `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`
      : `${address.state}`;
  }
}
