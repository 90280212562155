import { NgModule, isDevMode } from '@angular/core';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { DEFAULT_LANG_AS_STRING, SUPPORTED_LANGUAGES } from './available-langs.const';
import { TranslocoHttpLoader } from './transloco-http-loader.service';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: DEFAULT_LANG_AS_STRING,
        fallbackLang: DEFAULT_LANG_AS_STRING,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        missingHandler: {
          useFallbackTranslation: true,
        },
        prodMode: !isDevMode(),
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    // {
    //   provide: TRANSLOCO_SCOPE,
    //   useValue: 'shared',
    // },
  ],
})
export class TranslocoRootModule {}
