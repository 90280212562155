import { Injectable } from '@angular/core';

@Injectable()
export abstract class AuthLocalStorageService<T> {
  private loginDataKey = 'login_data_key';

  public storeLoginData(loginData: T): void {
    localStorage.setItem(this.loginDataKey, JSON.stringify(loginData));
  }
  public clearData(): void {
    localStorage.removeItem(this.loginDataKey);
  }

  public getLoginData(): T | undefined {
    const loginData = localStorage.getItem(this.loginDataKey);

    if (!loginData) {
      return undefined;
    }

    return JSON.parse(loginData);
  }
}
