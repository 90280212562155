import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApi } from '@web/shared/data-access/model';
import { AuthApiService } from '@web/web/shared/data-access/api';
import { BehaviorSubject, Observable, catchError, switchMap, take, throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthViewModel } from '../viewmodel/auth.viewmodel';

@Injectable()
export class RefreshTokenInterceptor<T> implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private isRefreshing = false;

  constructor(
    protected readonly authApiService: AuthApiService<T>,
    protected readonly authViewModel: AuthViewModel<T>,
    protected readonly router?: Router,
  ) {}

  /**
   * Intercepts requests and looks for 401 error
   *
   * If 401 error is found, handle401Error method is called
   * If its some other error, it will be ignored
   *
   * @param req
   * @param next
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (req.url.includes(AuthApi.REFRESH_TOKEN_ENDPOINT_URL)) {
          this.isRefreshing = false;

          if (!this.router?.url.includes('promotion')) {
            this.authViewModel.logout();
          }

          return throwError(error);
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(error);
      }),
    );
  }

  /**
   * Handles 401 (Authentication) error
   *
   * If some request fails, then interceptor will try to get new refresh token
   * If we are not able to get new refresh token and get second 401 error
   * User will be logged out and his data will be cleared from local storage
   *
   * @param req
   * @param next
   * @private
   */
  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      //  FIXME: Add types
      const authTokenPair: AuthApi.TokenPair = this.authViewModel.getLoginData() as any;

      return this.authApiService
        .signInViaRefreshToken({
          accessToken: String(authTokenPair?.accessToken),
          refreshToken: String(authTokenPair?.refreshToken),
        })
        .pipe(
          switchMap((loginResponse: T) => {
            this.isRefreshing = false;
            this.authViewModel.storeLoginData(loginResponse);
            this.refreshTokenSubject.next(loginResponse);

            return next.handle(this.addToken(req, (loginResponse as any).refreshToken));
          }),
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(loginResponse => next.handle(this.addToken(req, (loginResponse as any).refreshToken))),
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
