import { TextVariantApi } from './text-variant-api.namespace';

export namespace ProfessionalFieldApi {
  export interface ProfessionalField {
    id: string;
    textVariants: TextVariantApi.TextVariant[];
    slug: string;
    status: ProfessionalFieldStatus;
    title?: string;
  }

  export enum ProfessionalFieldStatus {
    PUBLIC = 'Public',
    PRIVATE = 'Private',
  }

  export interface CreateProfessionalField {
    id?: string;
    textVariants: TextVariantApi.CreateTextVariant[];
    slug: string;
    status: ProfessionalFieldStatus;
  }

  export interface UpdateProfessionalField {
    id?: string;
    textVariants?: TextVariantApi.UpdateTextVariant[];
    slug?: string;
    status?: ProfessionalFieldStatus;
  }
}
