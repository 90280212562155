<div class="dropdown-container {{ isFocused ? 'focused' : '' }} {{ isOpen ? 'opened' : '' }}">
  <!-- DEFAULT STATE -->
  @if (!disabled) {
    <div class="dropdown-label {{ value || isSearchActive ? 'stick-top' : '' }} {{ required ? 'required' : '' }}">
      {{ label }}
    </div>
  }

  <!-- DISABLED STATE WITH VALUE & HAS PLACEHOLDER -->
  @if (!!placeholder && !!value && disabled) {
    <div class="wh-s-label-above-input">
      {{ label }}
    </div>
  }

  <!-- DISABLED STATE NO VALUE & HAS PLACEHOLDER -->
  @if (!!placeholder && !value && disabled) {
    <div class="dropdown-label {{ value || isSearchActive ? 'stick-top' : '' }} {{ required ? 'required' : '' }}">
      {{ label }}
    </div>
  }

  <!-- Icon container (BEFORE input) -->
  <div class="wh-s-input-icon-before">
    <i-feather *ngIf="variant === 'danger' && !isFocused" class="icon-before" name="x-octagon"></i-feather>
    <i-feather *ngIf="variant === 'success' && !isFocused" class="icon-before" name="check-circle"></i-feather>
    <i-feather *ngIf="disabled" class="icon-before" name="slash"></i-feather>
  </div>

  <ng-select
    [ngStyle]="{ visibility: (loadingViewModel.isLoading$ | async) && showLoading ? 'hidden' : 'visible' }"
    [disabled]="disabled"
    [class]="variant"
    [items]="items"
    [clearable]="clearable"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [multiple]="multiple"
    [(ngModel)]="value"
    [formControl]="selectListControl"
    [compareWith]="compareFn"
    (search)="onSearch($event)"
    (change)="selectedItem.emit($event)"
    (focusin)="isFocused = true"
    (focusout)="isFocused = false"
    (open)="isOpen = true"
    (close)="isOpen = false"
    (clear)="clearValueAndEmit()"
  ></ng-select>

  <!-- LOADING ICON -->
  <div *ngIf="(loadingViewModel.isLoading$ | async) && showLoading" class="wh-s-input-icon-after">
    <wh-s-spinner />
  </div>
</div>

<!-- Message wrapper -->
<div *ngIf="(hasMessageWrapper && variant === 'danger' && !isFocused) || (hint && !isFocused)" class="message-wrapper">
  <p *ngIf="hint && !isFocused" class="wh-s-input--hint">{{ hint }}</p>
  <p *ngIf="variant === 'danger' && !isFocused" class="wh-s-input--hint--danger">{{ errorLabel }}</p>
</div>
