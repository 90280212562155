import { Pipe, PipeTransform } from '@angular/core';
import { JobApi } from '@web/shared/data-access/model';

@Pipe({
  name: 'employmentTypeToReadable',
  standalone: true,
})
export class EmploymentTypeToReadablePipe implements PipeTransform {
  public transform(empType: JobApi.EmploymentType): string {
    return JobApi.employmentTypeToHumanReadableValue(empType);
  }
}
