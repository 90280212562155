import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobrapidoApiService {
  private readonly url: string;
  constructor(private readonly httpClient: HttpClient) {
    this.url = `https://zelda.app.jobrapido.com/postback`;
  }

  public sendJobrapidoFeedbackEvent(jobrapidoFeedbackEvent: {
    clickId: string;
    clientCode: string;
    conversionType: string;
  }): Observable<void> {
    return this.httpClient.post<void>(this.url, jobrapidoFeedbackEvent);
  }
}
