import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApplicantApi, AuthApi } from '@web/shared/data-access/model';
import { AuthInterceptor, CLIENT_INJECTION_TOKEN } from '@web/web/core/auth/data-access';
import { ApplicantAuthViewModel } from '../viewmodel/applicant-auth.viewmodel';

@Injectable()
export class ApplicantAuthInterceptor extends AuthInterceptor<ApplicantApi.ApplicantLoginResponseDto> {
  constructor(
    router: Router,
    applicantAuthViewModel: ApplicantAuthViewModel,
    @Inject(CLIENT_INJECTION_TOKEN)
    client: AuthApi.Client,
    translocoService: TranslocoService,
  ) {
    super(router, applicantAuthViewModel, client, translocoService);
  }
}
