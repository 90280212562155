export namespace DriverLicenseApi {
  export interface DriverLicense {
    id?: string;
    licenseType: DriverLicenseType;
  }

  export interface DriverLicenseJob extends DriverLicense {
    years: number;
    required?: boolean;
  }

  export interface DriverLicenseApplicant extends DriverLicense {
    validFrom?: Date | undefined | null;
  }

  export interface CreateDriverLicenseJob {
    licenseType: DriverLicenseType;
    years: number;
    required?: boolean;
  }

  export enum DriverLicenseType {
    A = 'A',
    A1 = 'A1',
    A2 = 'A2',
    B = 'B',
    B96 = 'B96',
    BE = 'BE',
    AM = 'AM',
    C = 'C',
    C1 = 'C1',
    C1E = 'C1E',
    CE = 'CE',
    D = 'D',
    D1 = 'D1',
    D1E = 'D1E',
    DE = 'DE',
    L = 'L',
    T = 'T',
    NO = 'NO',
  }
}
