import { TranslocoApi } from './transloco-api.namespace';

export namespace LanguageApi {
  export interface Language {
    id?: string;
    language: TranslocoApi.OfficialLanguage;
    languageLevel: LanguageLevel;
    required?: boolean;
  }

  export enum LanguageLevel {
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    FLUENT = 'FLUENT',
    NATIVE = 'NATIVE',
  }

  export function languageLevelToFormattedText(level: LanguageLevel | undefined): string {
    switch (level) {
      case LanguageApi.LanguageLevel.BEGINNER:
        return 'Beginner';
      case LanguageApi.LanguageLevel.INTERMEDIATE:
        return 'Intermediate';
      case LanguageApi.LanguageLevel.ADVANCED:
        return 'Advanced';
      case LanguageApi.LanguageLevel.FLUENT:
        return 'Fluent';
      case LanguageApi.LanguageLevel.NATIVE:
        return 'Native';
      case undefined:
        return '';
    }
  }
}
