import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminApi } from '@web/shared/data-access/model';
import { GLOBAL_FEATURE_KEY, GlobalState } from './global.reducer';

export const selectGlobalState = createFeatureSelector<GlobalState>(GLOBAL_FEATURE_KEY);

export const selectRole = createSelector(selectGlobalState, state => state.role);

export const selectActiveEntityId = createSelector(selectGlobalState, state => state.activeEntityId);

export const selectIsAdmin = createSelector(selectRole, (role): boolean => {
  return (
    role === AdminApi.Role.DEVELOPER ||
    role === AdminApi.Role.SUPER_ADMIN ||
    role === AdminApi.Role.MARKETING ||
    role === AdminApi.Role.APPLICANT_SERVICE_AGENT ||
    role === AdminApi.Role.CUSTOMER_SUCCESS_MANAGER
  );
});
