import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PromoterApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth/auth-api.service';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class PromoterApiService extends AuthApiService<PromoterApi.PromoterLoginResponse> {
  public readonly afterLogoutRoute = '/auth';

  constructor(httpClient: HttpClient, @Inject(BASE_URL_TOKEN) baseUrl: string) {
    super(`${baseUrl}/promoters`, httpClient);
  }

  public register(registerPromoter: PromoterApi.RegisterPromoter): Observable<PromoterApi.PromoterLoginResponse> {
    return this.httpClient.post<PromoterApi.PromoterLoginResponse>(`${this.url}/register`, registerPromoter);
  }

  public getMany(pageSize: number, currentPage: number): Observable<PaginateResult<PromoterApi.Promoter>> {
    return this.httpClient.get<PaginateResult<PromoterApi.Promoter>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public getAll(): Observable<PromoterApi.Promoter[]> {
    return this.httpClient.get<PromoterApi.Promoter[]>(`${this.url}/all`);
  }

  public create(createPromoterDto: PromoterApi.CreatePromoter): Observable<PromoterApi.Promoter> {
    return this.httpClient.post<PromoterApi.Promoter>(this.url, createPromoterDto);
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  public edit(editPromoterDto: PromoterApi.EditPromoter): Observable<PromoterApi.Promoter> {
    return this.httpClient.put<PromoterApi.Promoter>(`${this.url}/${editPromoterDto.id}`, editPromoterDto);
  }
}
