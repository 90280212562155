import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AssessmentApi } from '@web/shared/data-access/model';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class AssessmentApiService {
  private readonly url: string;
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/assessment`;
  }

  public getAssessmentDetailsById(id: string): Observable<AssessmentApi.AssessmentDetails> {
    return this.httpClient.get<AssessmentApi.AssessmentDetails>(`${this.url}/details/${id}`);
  }

  public submitApplicantsAssessment(assessmentId: string, answers: string[]): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/applicant-participation`, {
      assessmentId: assessmentId,
      answers,
    });
  }

  public getApplicantParticipations(applicantId: string): Observable<AssessmentApi.ApplicantParticipationWrapper> {
    return this.httpClient.get<AssessmentApi.ApplicantParticipationWrapper>(
      `${this.url}/applicant-participation/${applicantId}`,
    );
  }

  public getAssessmentParticipationsByApplicantIdAndJobId(
    applicantId: string,
    jobId: string,
  ): Observable<AssessmentApi.ApplicantParticipation[]> {
    return this.httpClient.get<AssessmentApi.ApplicantParticipation[]>(
      `${this.url}/jobs/${jobId}/applicants/${applicantId}/assessment-participations`,
    );
  }

  public enable2ndChance(assessmentId: string, applicantId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.url}/enable-2nd-chance/${assessmentId}/${applicantId}`, {});
  }

  /**
   * Sends an assessment reminder email to the specified applicant.
   *
   * @param {string} assessmentId - the ID of the assessment
   * @param {string} applicantId - the ID of the applicant
   * @return {Observable<void>} an observable of void
   */
  public sendAssessmentReminderEmail(assessmentId: string, applicantId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${assessmentId}/applicants/${applicantId}/reminder-email`, {});
  }

  public updateAssessmentParticipationStatus(
    participationId: string,
    status: AssessmentApi.AssessmentParticipationStatus,
  ): Observable<void> {
    return this.httpClient.patch<void>(`${this.url}/participations/${participationId}/status`, { status });
  }
}
