import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-spinner',
  standalone: true,
  imports: [CommonModule, FeatherModule],
  template: '<i-feather class="loading-icon" name="loader"></i-feather>',
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;

        & > i-feather {
          cursor: wait;
          width: 1.5rem;
          color: #5e5e5e;
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class SpinnerComponent {}
