import { Injectable } from '@angular/core';
import { AuthApiService } from '@web/web/shared/data-access/api';
import { take, tap } from 'rxjs';

@Injectable()
export abstract class VerificationViewModel<T> {
  public continueBtnDisabled = true;

  protected constructor(private readonly employerApiService: AuthApiService<T>) {}

  public verifyUser(id: string): void {
    this.employerApiService
      .verifyUser(id)
      .pipe(
        take(1),
        tap(() => (this.continueBtnDisabled = false)),
      )
      .subscribe();
  }
}
