import { Pipe, PipeTransform } from '@angular/core';
import { TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';

@Pipe({
  name: 'variantFallbackPipe',
  standalone: true,
})
export class VariantFallbackPipePipe implements PipeTransform {
  public transform(titleVariants: TextVariantApi.TextVariant[], currentLocale: TranslocoApi.Locale): string {
    return (
      titleVariants.filter(t => t.value).find(t => t.locale === currentLocale)?.value ??
      titleVariants.find(t => t.value)?.value ??
      ''
    );
  }
}
