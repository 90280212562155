import { JobTypeCategoryApi } from './job-type-category-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace JobTypeApi {
  export interface JobType {
    id: string;
    status: JobTypeStatus;
    slug: string;
    textVariants: TextVariantApi.TextVariant[];
    title?: string;
    jobTypeCategory?: JobTypeCategoryApi.JobTypeCategory;
    requirementLevel: JobTypeApi.RequirementLevel;
  }

  export interface CreateJobType {
    status: JobTypeStatus;
    jobTypeCategoryId: string;
    slug: string;
    textVariants: TextVariantApi.CreateTextVariant[];
    requirementLevel: JobTypeApi.RequirementLevel;
  }

  export interface UpdateJobType {
    id: string;
    jobTypeCategoryId: string;
    status?: JobTypeStatus;
    slug?: string;
    textVariants: TextVariantApi.UpdateTextVariant[];
    requirementLevel: JobTypeApi.RequirementLevel;
  }

  export enum JobTypeStatus {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
  }

  export enum RequirementLevel {
    HIGHLY_COMPLEX = 'HIGHLY_COMPLEX',
    COMPLEX = 'COMPLEX',
    PROFESSIONAL = 'PROFESSIONAL',
    LEARNING = 'LEARNING',
    NONE = 'NONE',
  }
}
