import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminApi, AuthApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth/auth-api.service';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService extends AuthApiService<AdminApi.AdminLoginResponseDto> {
  public readonly afterLogoutRoute = '/auth/login';

  constructor(httpClient: HttpClient, @Inject(BASE_URL_TOKEN) baseUrl: string) {
    super(`${baseUrl}/admins`, httpClient);
  }

  public getMany(pageSize: number, currentPage: number): Observable<PaginateResult<AdminApi.Admin>> {
    return this.httpClient.get<PaginateResult<AdminApi.Admin>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public create(createAdmin: AdminApi.RegisterAdmin): Observable<AdminApi.Admin> {
    return this.httpClient.post<AdminApi.Admin>(this.url, createAdmin);
  }

  public update(updateAdmin: AdminApi.UpdateAdmin): Observable<AdminApi.Admin> {
    return this.httpClient.put<AdminApi.Admin>(this.url, updateAdmin);
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
