import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AlertItem } from '@web/web/shared/ui/alert-item';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

interface AlertState {
  items: AlertItem[];
}

@Injectable({
  providedIn: 'root',
})
export class AlertService extends ComponentStore<AlertState> {
  private readonly GENERIC_CRUD_KEY = 'shared.ui.alert.generic-crud';
  private readonly GENERIC_SUCCESS_KEY = 'shared.ui.alert.title.success';
  private readonly GENERIC_ERROR_KEY = 'shared.ui.alert.title.error';

  public readonly vm$: Observable<AlertState> = this.select(state => ({
    items: state.items,
  }));

  constructor() {
    super({
      items: [],
    });
  }

  public addAlert(
    type: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'light' | 'dark' | 'danger',
    text: string,
    title?: string,
    timeout?: number,
  ): void {
    const defaultTimeout = 5000;
    const newAlertItem: AlertItem = { id: uuidv4(), type, text, title: title ?? '' };

    this.patchState({ items: [...this.items, newAlertItem] });

    this.setDestroyTimer(newAlertItem.id, defaultTimeout ?? timeout);
  }

  public success(text: string, title = 'shared.ui.alert.title.success', timeout?: number): void {
    this.addAlert('success', text, title, timeout);
  }

  public createSuccess(): void {
    this.addAlert('success', `${this.GENERIC_CRUD_KEY}.create.success.text`, this.GENERIC_SUCCESS_KEY);
  }

  public createError(): void {
    this.addAlert('danger', `${this.GENERIC_CRUD_KEY}.create.error.text`, this.GENERIC_ERROR_KEY);
  }

  public updateSuccess(): void {
    this.addAlert('success', `${this.GENERIC_CRUD_KEY}.update.success.text`, this.GENERIC_SUCCESS_KEY);
  }

  public updateError(): void {
    this.addAlert('danger', `${this.GENERIC_CRUD_KEY}.update.error.text`, this.GENERIC_ERROR_KEY);
  }

  public fetchSuccess(): void {
    this.addAlert('success', `${this.GENERIC_CRUD_KEY}.read.success.text`, this.GENERIC_SUCCESS_KEY);
  }

  public fetchError(): void {
    this.addAlert('danger', `${this.GENERIC_CRUD_KEY}.read.error.text`, this.GENERIC_ERROR_KEY);
  }

  public deleteSuccess(): void {
    this.addAlert('success', `${this.GENERIC_CRUD_KEY}.delete.success.text`, this.GENERIC_SUCCESS_KEY);
  }

  public deleteError(): void {
    this.addAlert('danger', `${this.GENERIC_CRUD_KEY}.delete.error.text`, this.GENERIC_ERROR_KEY);
  }

  public error(text: string, title = 'shared.ui.alert.title.error', timeout?: number): void {
    this.addAlert('danger', text, title, timeout);
  }

  public warning(text: string, title = 'shared.ui.alert.title.warning', timeout?: number): void {
    this.addAlert('warning', text, title, timeout);
  }

  public info(text: string, title = 'shared.ui.alert.title.info', timeout?: number): void {
    this.addAlert('info', text, title, timeout);
  }

  public removeAlert(id: string): void {
    this.patchState({ items: this.items.filter(alertItem => alertItem.id !== id) });
  }

  private get items(): AlertItem[] {
    return this.get().items;
  }

  private setDestroyTimer(alertItemId: string, timeout: number): void {
    setTimeout(() => this.removeAlert(alertItemId), timeout);
  }
}
