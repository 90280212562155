import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'inputVariant',
  standalone: true,
  pure: false,
})
export class InputVariantPipe implements PipeTransform {
  public transform(fg: FormGroup, controlName: string): 'danger' | 'default' | 'success' {
    return !fg.get(controlName)?.dirty ? 'default' : fg.get(controlName)?.invalid ? 'danger' : 'success';
  }
}
