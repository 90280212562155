import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthViewModel } from '../viewmodel/auth.viewmodel';

@Injectable()
export abstract class LoggedInGuard<T> {
  protected constructor(
    protected readonly authViewModel: AuthViewModel<T>,
    protected readonly router: Router,
    protected readonly homeRoute: string,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasDataStoredLocally = this.authViewModel.getLoginData();

    if (hasDataStoredLocally) {
      return this.router.createUrlTree([this.homeRoute], { queryParamsHandling: 'preserve' });
    }

    return true;
  }
}
