import { HereMapsApi } from './here-maps.namespace';

export namespace ApplicantEducationApi {
  export interface Education {
    id?: string;
    title?: string;
    address: HereMapsApi.AddressData;
    name: string;
    fromDate: Date;
    toDate?: Date;
    degree?: Degree;
  }

  export enum Degree {
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    MASTER = 'MASTER',
    BACHELOR = 'BACHELOR',
    DIPLOMA = 'DIPLOMA',
    DOCTORATE = 'DOCTORATE',
    PROFESSORSHIP = 'PROFESSORSHIP',
    MAGISTER_DEGREE = 'MAGISTER_DEGREE',
    BASIC_SCHOOL_CERTIFICATE = 'BASIC_SCHOOL_CERTIFICATE',
    UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE = 'UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE',
    SECONDARY_SCHOOL_CERTIFICATE = 'SECONDARY_SCHOOL_CERTIFICATE',
    LANGUAGE_COURSE = 'LANGUAGE_COURSE',
  }

  export function getEducationDegreeTranslationString(degree: Degree): string {
    switch (degree) {
      case ApplicantEducationApi.Degree.HIGH_SCHOOL:
        return 'shared.ui.education.HIGH_SCHOOL';
      case ApplicantEducationApi.Degree.MASTER:
        return 'shared.ui.education.MASTER';
      case ApplicantEducationApi.Degree.BACHELOR:
        return 'shared.ui.education.BACHELOR';
      case ApplicantEducationApi.Degree.DIPLOMA:
        return 'shared.ui.education.DIPLOMA';
      case ApplicantEducationApi.Degree.DOCTORATE:
        return 'shared.ui.education.DOCTORATE';
      case ApplicantEducationApi.Degree.PROFESSORSHIP:
        return 'shared.ui.education.PROFESSORSHIP';
      case ApplicantEducationApi.Degree.MAGISTER_DEGREE:
        return 'shared.ui.education.MAGISTER_DEGREE';
      case ApplicantEducationApi.Degree.BASIC_SCHOOL_CERTIFICATE:
        return 'shared.ui.education.BASIC_SCHOOL_CERTIFICATE';
      case ApplicantEducationApi.Degree.UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE:
        return 'shared.ui.education.UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE';
      case ApplicantEducationApi.Degree.SECONDARY_SCHOOL_CERTIFICATE:
        return 'shared.ui.education.SECONDARY_SCHOOL_CERTIFICATE';
      case ApplicantEducationApi.Degree.LANGUAGE_COURSE:
        return 'shared.ui.education.LANGUAGE_COURSE';
    }
  }
}
