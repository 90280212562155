import { OccupationalSubgroupApi } from './occupational-subgroup-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace JobTypeCategoryApi {
  export enum Status {
    PRIVATE = 'Private',
    PUBLIC = 'Public',
  }

  export interface JobTypeCategory {
    id: string;
    textVariants: TextVariantApi.TextVariant[];
    occupationalSubgroup: OccupationalSubgroupApi.OccupationalSubgroup;
    status: Status;
  }

  export interface CreateJobTypeCategory {
    textVariants: TextVariantApi.CreateTextVariant[];
    occupationalSubgroupId: string;
    status: Status;
  }

  export interface UpdateJobTypeCategory {
    id: string;
    textVariants: TextVariantApi.UpdateTextVariant[];
    occupationalSubgroupId: string;
    status: Status;
  }
}
