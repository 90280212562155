import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthApi } from '@web/shared/data-access/model';
import { LoginViewModel } from '@web/web/core/auth/data-access';
import { AlertService } from '@web/web/shared/data-access/alert';
import { ApplicantApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { ApplicantAuthViewModel } from './applicant-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class ApplicantLoginViewModel extends LoginViewModel {
  protected defaultRedirectUrl = '/dashboard';

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    private readonly applicantApiService: ApplicantApiService,
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly alertService: AlertService,
    private readonly jobApplicationApiService: JobApplicationApiService,
  ) {
    super(formBuilder, router);
  }

  public submit(): void {
    const loginData = this.getLoginData();

    if (!loginData) {
      return;
    }

    this.applicantApiService
      .login({
        ...loginData,
        client: AuthApi.Client.APPLICANT_APP,
      })
      .pipe(
        tap(loginResponseDto => {
          this.redirect();

          this.applicantAuthViewModel.storeLoginData(loginResponseDto);

          const externalApplicationData = localStorage.getItem(this.applicantAuthViewModel.externalApplicationDataKey);

          if (externalApplicationData) {
            const data = JSON.parse(externalApplicationData);

            this.jobApplicationApiService
              .create(data)
              .pipe(
                take(1),
                tap(jobApplication => {
                  if (jobApplication.currentStep?.assessment) {
                    this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
                  }

                  this.applicantAuthViewModel.removeExternalApplicationData();
                }),
                catchError(err => {
                  if (err.status === 409) {
                    this.applicantAuthViewModel.removeExternalApplicationData();
                  }

                  return EMPTY;
                }),
              )
              .subscribe();

            return;
          }

          this.alertService.success(`You are logged in. Welcome ${loginResponseDto.firstName}`);
        }),
      )
      .subscribe();
  }
}
