import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class ZohoCrmApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) baseUrl: string,
  ) {
    this.url = `${baseUrl}/zoho-crm`;
  }

  public getApplicantsLink(email: string, phoneNumber: string): Observable<string> {
    return this.httpClient.get(`${this.url}/applicants/${email}?applicantPhone=${phoneNumber}`, {
      responseType: 'text',
    });
  }

  public updateApplicantRating(applicantId: string, rating: string): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/applicants/${applicantId}`, { rating });
  }
}
