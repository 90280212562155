import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { AlertService } from '@web/web/shared/data-access/alert';
import { AuthApiService } from '@web/web/shared/data-access/api';
//  TODO: Fix imports
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TimerService } from '@web/web/shared/data-access/timer';
//  TODO: Fix imports
// eslint-disable-next-line @nx/enforce-module-boundaries
import { confirmPasswordValidatorFunction } from '@web/web/shared/util/function';
import { EMPTY, Observable, catchError, take, tap } from 'rxjs';

export interface ResetPasswordState {
  email: string;
  userId: string;
  token: string;
  timerValue: number;
}

export interface ResetPasswordStateView {
  timerValue: number;
}

@Injectable()
export abstract class ResetPasswordViewModel<T> extends ComponentStore<ResetPasswordState> {
  public vm$: Observable<ResetPasswordStateView> = this.select(state => ({
    timerValue: state.timerValue,
  }));

  public newPasswordFormGroup: FormGroup;
  public emailFormGroup: FormGroup;

  protected constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authApiService: AuthApiService<T>,
    private readonly alertService: AlertService,
    private readonly timerService: TimerService,
    private readonly router: Router,
  ) {
    super({
      email: '',
      token: '',
      userId: '',
      timerValue: 0,
    });
  }

  public buildNewPasswordForm(userId: string, email: string, token: string): void {
    this.patchState({ userId, email, token });

    this.newPasswordFormGroup = this.formBuilder.group(
      {
        email: new FormControl({ value: email, disabled: true }, [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        passwordRepeat: new FormControl('', [Validators.required]),
      },
      {
        validator: confirmPasswordValidatorFunction('password', 'passwordRepeat'),
      },
    );
  }

  public buildEnterEmailForm(): void {
    this.emailFormGroup = this.formBuilder.group({
      email: new FormControl(this.get().email, [Validators.required, Validators.email]),
    });
  }

  public initTimerSubscription(): void {
    this.timerService.timerValue$.pipe(tap(timerValue => this.patchState({ timerValue }))).subscribe();
  }

  public sendResetEmail(): void {
    if (!this.emailFormGroup.valid) {
      return;
    }

    if (this.timerService.isTimerActive()) {
      return;
    }

    const timerCountdown = 60;
    this.timerService.createTimer(timerCountdown);

    this.authApiService
      .sendResetPasswordEmail(this.emailFormGroup.value.email)
      .pipe(
        take(1),
        tap(() => {
          this.alertService.info(
            'If there is user with provided email, password reset instructions will be sent.',
            'Info',
            10000,
          );
        }),
      )
      .subscribe();
  }

  public resetPassword(): void {
    const { userId, email, token } = this.get();
    const { password } = this.newPasswordFormGroup.value;

    this.authApiService
      .resetPassword(userId, email, token, password)
      .pipe(
        take(1),
        catchError(err => {
          this.alertService.error('Link is expired, used or invalid.');

          return EMPTY;
        }),
        tap(() => {
          this.router.navigate(['/auth']);
          this.alertService.info('Password successfully updated.');
        }),
      )
      .subscribe();
  }
}
