import { CompanyApi } from './company-api.namespace';
import { TranslocoApi } from './transloco-api.namespace';

export namespace OpenAiApi {
  export interface ImprovedText {
    original: string;
    improved: string;
  }

  export interface GeneratedText {
    text: string;
  }

  export interface TranslateTextVariant {
    text: string;
    locale: TranslocoApi.Locale;
  }

  export interface GenerateJobDescription {
    jobTitle: string;
    address: string;
    employmentTypes: string[];
    compensations?: string[] | null | undefined;
    jobType: string;
    locale: TranslocoApi.Locale;
    companyName: string;
  }

  export interface GenerateCompanyDescription {
    name: string;
    address: string;
    locale: TranslocoApi.Locale;
    professionalField?: string;
    companySize?: CompanyApi.CompanySize;
  }

  export interface GenerateAssessment {
    jobTitle: string;
    jobType: string;
    professionalField: string;
    locale: TranslocoApi.Locale;
    hasAutoAssessment: boolean;
    score: number;
  }

  export interface GenerateJobTitle {
    address: string;
    employmentTypes: string;
    jobType: string;
  }

  export enum ActionType {
    GENERATE_DESCRIPTION = 'GENERATE_DESCRIPTION',
    TRANSLATE = 'TRANSLATE',
    CORRECT_GRAMMAR = 'CORRECT_GRAMMAR',
  }
}
