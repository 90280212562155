<div class="main-page">
  <!-- ******************************** -->
  <!-- PAGE TITLE -->
  <!-- ******************************** -->
  <div class="page-title" #mainPageTitle>
    <div *ngIf="canStepBack" class="step-back-wrapper">
      <i-feather name="arrow-left" (click)="stepBack.emit()"></i-feather>
    </div>

    @if (title) {
      <h3 class="wh-header-lg">{{ title }}</h3>
    }
  </div>

  <!-- ******************************** -->
  <!-- PAGE ACTIONS -->
  <!-- ******************************** -->
  <div class="action-wrapper">
    <div class="flex-item">
      <!-- ******************************** -->
      <!-- ACTION LEFT CONTAINER -->
      <!-- ******************************** -->
      <ng-content select="[slot=action-left]"></ng-content>
    </div>

    <div class="flex-item">
      <!-- ******************************** -->
      <!-- ACTION RIGHT CONTAINER -->
      <!-- ******************************** -->
      <ng-content select="[slot=action-right]"></ng-content>
    </div>
  </div>

  <!-- ******************************** -->
  <!-- PAGE CUSTOM ACTIONS -->
  <!-- ******************************** -->
  <div class="custom-content-wrapper">
    <ng-content select="[slot=custom-content]"></ng-content>
  </div>

  <!-- ******************************** -->
  <!-- PAGE MAIN CONTENT -->
  <!-- ******************************** -->
  <div class="content-wrapper">
    <ng-content select="[slot=content]"></ng-content>
  </div>
</div>
