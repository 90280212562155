// eslint-disable-next-line @nx/enforce-module-boundaries
import { DropdownOption } from '@web/web/shared/ui/dropdown';
import { JobApi } from './job-api.namespace';
import { PromoterApi } from './promoter-api.namespace';
import { UtmApi } from './utm.namespace';

export namespace PromotionApi {
  export interface Promotion {
    id?: string;
    percentage: number;
    totalPayout: number;
    status: PromotionStatus;
    promoters: PromoterApi.Promoter[];
    V1jobId: number;
    jobId: string;
    job?: DropdownOption;
    header?: string;
    videoLink?: string;
    text?: string;
    companyName?: string;
    companyLogo?: string;
    companyAddress?: string; // TODO: Use here maps address
    image?: string;
    distance?: number;
    marketingMaterialLink?: string;
    utm?: UtmApi.UtmData;
  }

  export interface V1Job {
    companyLogo: string;
    header: string;
    text: string;
    videoLink: string;
  }

  export interface PromotionDetail extends Promotion, Partial<JobApi.JobDetails> {
    link: string;
  }

  export enum PromotionStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    SUCCESS = 'Success',
  }
}
