import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emojiText',
  standalone: true,
})
export class EmojiTextPipe implements PipeTransform {
  public transform(value: string): string {
    let retVal = value;

    retVal = this.replaceAll(retVal, ':fire:', '🔥');
    retVal = this.replaceAll(retVal, ':money_with_wings:', '💸');
    retVal = this.replaceAll(retVal, ':grinning:', '😀');
    retVal = this.replaceAll(retVal, ':grin:', '😁');
    retVal = this.replaceAll(retVal, ':joy:', '😂');
    retVal = this.replaceAll(retVal, ':smiley:', '😃');
    retVal = this.replaceAll(retVal, ':smile:', '😄');
    retVal = this.replaceAll(retVal, ':sweat_smile:', '😅');
    retVal = this.replaceAll(retVal, ':laughing:', '😆');
    retVal = this.replaceAll(retVal, ':innocent:', '😇');
    retVal = this.replaceAll(retVal, ':wink:', '😉');
    retVal = this.replaceAll(retVal, ':blush:', '😊');
    retVal = this.replaceAll(retVal, ':slightly_smiling_face:', '🙂');
    retVal = this.replaceAll(retVal, ':upside_down_face:', '🙃');
    retVal = this.replaceAll(retVal, ':relaxed:', '☺️');
    retVal = this.replaceAll(retVal, ':yum:', '😋');
    retVal = this.replaceAll(retVal, ':relieved:', '😌');
    retVal = this.replaceAll(retVal, ':heart_eyes:', '😍');
    retVal = this.replaceAll(retVal, ':kissing_heart:', '😘');
    retVal = this.replaceAll(retVal, ':kissing:', '😗');
    retVal = this.replaceAll(retVal, ':kissing_smiling_eyes:', '😙');
    retVal = this.replaceAll(retVal, ':kissing_closed_eyes:', '😚');
    retVal = this.replaceAll(retVal, ':stuck_out_tongue_winking_eye:', '😜');
    retVal = this.replaceAll(retVal, ':stuck_out_tongue_closed_eyes:', '😝');
    retVal = this.replaceAll(retVal, ':stuck_out_tongue:', '😛');
    retVal = this.replaceAll(retVal, ':money_mouth_face:', '🤑');
    retVal = this.replaceAll(retVal, ':nerd_face:', '🤓');
    retVal = this.replaceAll(retVal, ':sunglasses:', '😎');
    retVal = this.replaceAll(retVal, ':clown_face:', '🤡');
    retVal = this.replaceAll(retVal, ':cowboy:', '🤠');
    retVal = this.replaceAll(retVal, ':hugs:', '🤗');
    retVal = this.replaceAll(retVal, ':smirk:', '😏');
    retVal = this.replaceAll(retVal, ':no_mouth:', '😶');
    retVal = this.replaceAll(retVal, ':neutral_face:', '😐');
    retVal = this.replaceAll(retVal, ':expressionless:', '😑');
    retVal = this.replaceAll(retVal, ':unamused:', '😒');
    retVal = this.replaceAll(retVal, ':roll_eyes:', '🙄');
    retVal = this.replaceAll(retVal, ':thinking:', '🤔');
    retVal = this.replaceAll(retVal, ':lying_face:', '🤥');
    retVal = this.replaceAll(retVal, ':hand_over_mouth:', '🤭');
    retVal = this.replaceAll(retVal, ':shushing_face:', '🤫');
    retVal = this.replaceAll(retVal, ':symbols_over_mouth:', '🤬');
    retVal = this.replaceAll(retVal, ':angry:', '😠');
    retVal = this.replaceAll(retVal, ':rage:', '😡');
    retVal = this.replaceAll(retVal, ':triumph:', '😤');
    retVal = this.replaceAll(retVal, ':open_mouth:', '😮');
    retVal = this.replaceAll(retVal, ':hushed:', '😯');
    retVal = this.replaceAll(retVal, ':cold_sweat:', '😰');
    retVal = this.replaceAll(retVal, ':scream:', '😱');
    retVal = this.replaceAll(retVal, ':fearful:', '😨');
    retVal = this.replaceAll(retVal, ':weary:', '😩');
    retVal = this.replaceAll(retVal, ':tired_face:', '😫');
    retVal = this.replaceAll(retVal, ':confounded:', '😖');
    retVal = this.replaceAll(retVal, ':persevere:', '😣');
    retVal = this.replaceAll(retVal, ':disappointed:', '😞');
    retVal = this.replaceAll(retVal, ':sweat:', '😓');
    retVal = this.replaceAll(retVal, ':pensive:', '😔');
    retVal = this.replaceAll(retVal, ':confused:', '😕');
    retVal = this.replaceAll(retVal, ':slightly_frowning_face:', '🙁');
    retVal = this.replaceAll(retVal, ':frowning:', '☹️');
    retVal = this.replaceAll(retVal, ':persevere:', '😣');
    retVal = this.replaceAll(retVal, ':confounded:', '😖');
    retVal = this.replaceAll(retVal, ':disappointed_relieved:', '😥');
    retVal = this.replaceAll(retVal, ':cry:', '😢');
    retVal = this.replaceAll(retVal, ':sob:', '😭');
    retVal = this.replaceAll(retVal, ':triumph:', '😤');
    retVal = this.replaceAll(retVal, ':disappointed:', '😞');
    retVal = this.replaceAll(retVal, ':anguished:', '😧');
    retVal = this.replaceAll(retVal, ':fearful:', '😨');
    retVal = this.replaceAll(retVal, ':weary:', '😩');
    retVal = this.replaceAll(retVal, ':exploding_head:', '🤯');
    retVal = this.replaceAll(retVal, ':grimacing:', '😬');
    retVal = this.replaceAll(retVal, ':cold_sweat:', '😰');
    retVal = this.replaceAll(retVal, ':scream:', '😱');
    retVal = this.replaceAll(retVal, ':flushed:', '😳');
    retVal = this.replaceAll(retVal, ':dizzy_face:', '😵');
    retVal = this.replaceAll(retVal, ':rage:', '😡');
    retVal = this.replaceAll(retVal, ':angry:', '😠');
    retVal = this.replaceAll(retVal, ':mask:', '😷');
    retVal = this.replaceAll(retVal, ':face_with_thermometer:', '🤒');
    retVal = this.replaceAll(retVal, ':face_with_head_bandage:', '🤕');
    retVal = this.replaceAll(retVal, ':nauseated_face:', '🤢');
    retVal = this.replaceAll(retVal, ':vomiting_face:', '🤮');
    retVal = this.replaceAll(retVal, ':sneezing_face:', '🤧');
    retVal = this.replaceAll(retVal, ':hot_face:', '🥵');
    retVal = this.replaceAll(retVal, ':cold_face:', '🥶');
    retVal = this.replaceAll(retVal, ':woozy_face:', '🥴');
    retVal = this.replaceAll(retVal, ':heart:', '❤️');
    retVal = this.replaceAll(retVal, ':orange_heart:', '🧡');
    retVal = this.replaceAll(retVal, ':yellow_heart:', '💛');
    retVal = this.replaceAll(retVal, ':green_heart:', '💚');
    retVal = this.replaceAll(retVal, ':blue_heart:', '💙');
    retVal = this.replaceAll(retVal, ':purple_heart:', '💜');
    retVal = this.replaceAll(retVal, ':black_heart:', '🖤');
    retVal = this.replaceAll(retVal, ':brown_heart:', '🤎');
    retVal = this.replaceAll(retVal, ':white_heart:', '🤍');
    retVal = this.replaceAll(retVal, ':broken_heart:', '💔');
    retVal = this.replaceAll(retVal, ':heartbeat:', '💓');
    retVal = this.replaceAll(retVal, ':two_hearts:', '💕');
    retVal = this.replaceAll(retVal, ':sparkling_heart:', '💖');
    retVal = this.replaceAll(retVal, ':heartpulse:', '💗');
    retVal = this.replaceAll(retVal, ':cupid:', '💘');
    retVal = this.replaceAll(retVal, ':gift_heart:', '💝');
    retVal = this.replaceAll(retVal, ':heart_decoration:', '💟');
    retVal = this.replaceAll(retVal, ':peace:', '☮️');
    retVal = this.replaceAll(retVal, ':cross:', '✝️');
    retVal = this.replaceAll(retVal, ':star_and_crescent:', '☪️');
    retVal = this.replaceAll(retVal, ':om:', '🕉️');
    retVal = this.replaceAll(retVal, ':wheel_of_dharma:', '☸️');
    retVal = this.replaceAll(retVal, ':star_of_david:', '✡️');
    retVal = this.replaceAll(retVal, ':six_pointed_star:', '🔯');
    retVal = this.replaceAll(retVal, ':menorah:', '🕎');
    retVal = this.replaceAll(retVal, ':yin_yang:', '☯️');
    retVal = this.replaceAll(retVal, ':orthodox_cross:', '☦️');
    retVal = this.replaceAll(retVal, ':place_of_worship:', '🛐');
    retVal = this.replaceAll(retVal, ':pray:', '🙏');
    retVal = this.replaceAll(retVal, ':atom:', '⚛️');
    retVal = this.replaceAll(retVal, ':om_symbol:', '🕉️');
    retVal = this.replaceAll(retVal, ':star:', '⭐');
    retVal = this.replaceAll(retVal, ':dizzy:', '💫');
    retVal = this.replaceAll(retVal, ':sparkles:', '✨');
    retVal = this.replaceAll(retVal, ':eight_spoked_asterisk:', '✳️');
    retVal = this.replaceAll(retVal, ':eight_pointed_black_star:', '✴️');
    retVal = this.replaceAll(retVal, ':snowflake:', '❄️');
    retVal = this.replaceAll(retVal, ':sparkle:', '❇️');
    retVal = this.replaceAll(retVal, ':exclamation:', '❗');
    retVal = this.replaceAll(retVal, ':question:', '❓');
    retVal = this.replaceAll(retVal, ':grey_question:', '❔');
    retVal = this.replaceAll(retVal, ':grey_exclamation:', '❕');
    retVal = this.replaceAll(retVal, ':red_exclamation:', '❗');
    retVal = this.replaceAll(retVal, ':red_question:', '❓');
    retVal = this.replaceAll(retVal, ':100:', '💯');
    retVal = this.replaceAll(retVal, ':anger:', '💢');
    retVal = this.replaceAll(retVal, ':boom:', '💥');
    retVal = this.replaceAll(retVal, ':sweat_drops:', '💦');
    retVal = this.replaceAll(retVal, ':dash:', '💨');
    retVal = this.replaceAll(retVal, ':dizzy:', '💫');
    retVal = this.replaceAll(retVal, ':hole:', '🕳️');
    retVal = this.replaceAll(retVal, ':bomb:', '💣');
    retVal = this.replaceAll(retVal, ':speech_balloon:', '💬');
    retVal = this.replaceAll(retVal, ':left_speech_bubble:', '🗨️');
    retVal = this.replaceAll(retVal, ':right_anger_bubble:', '🗯️');
    retVal = this.replaceAll(retVal, ':thought_balloon:', '💭');
    retVal = this.replaceAll(retVal, ':zzz:', '💤');
    retVal = this.replaceAll(retVal, ':wave:', '👋');
    retVal = this.replaceAll(retVal, ':raised_back_of_hand:', '🤚');
    retVal = this.replaceAll(retVal, ':hand:', '✋');
    retVal = this.replaceAll(retVal, ':victory_hand:', '✌️');
    retVal = this.replaceAll(retVal, ':ok_hand:', '👌');
    retVal = this.replaceAll(retVal, ':pinching_hand:', '🤏');
    retVal = this.replaceAll(retVal, ':pinched_fingers:', '🤌');
    retVal = this.replaceAll(retVal, ':crossed_fingers:', '🤞');
    retVal = this.replaceAll(retVal, ':love_you_gesture:', '🤟');
    retVal = this.replaceAll(retVal, ':metal:', '🤘');
    retVal = this.replaceAll(retVal, ':call_me_hand:', '🤙');
    retVal = this.replaceAll(retVal, ':point_left:', '👈');
    retVal = this.replaceAll(retVal, ':point_right:', '👉');
    retVal = this.replaceAll(retVal, ':point_up_2:', '👆');
    retVal = this.replaceAll(retVal, ':point_down:', '👇');
    retVal = this.replaceAll(retVal, ':point_up:', '☝️');
    retVal = this.replaceAll(retVal, ':thumbsup:', '👍');
    retVal = this.replaceAll(retVal, ':thumbsdown:', '👎');
    retVal = this.replaceAll(retVal, ':raised_hand:', '✋');
    retVal = this.replaceAll(retVal, ':raised_hands:', '🙌');
    retVal = this.replaceAll(retVal, ':clap:', '👏');
    retVal = this.replaceAll(retVal, ':handshake:', '🤝');
    retVal = this.replaceAll(retVal, ':pray:', '🙏');
    retVal = this.replaceAll(retVal, ':writing_hand:', '✍️');
    retVal = this.replaceAll(retVal, ':nail_care:', '💅');
    retVal = this.replaceAll(retVal, ':lips:', '👄');
    retVal = this.replaceAll(retVal, ':tongue:', '👅');
    retVal = this.replaceAll(retVal, ':ear:', '👂');
    retVal = this.replaceAll(retVal, ':nose:', '👃');
    retVal = this.replaceAll(retVal, ':eye:', '👁️');
    retVal = this.replaceAll(retVal, ':eyes:', '👀');
    retVal = this.replaceAll(retVal, ':bust_in_silhouette:', '👤');
    retVal = this.replaceAll(retVal, ':busts_in_silhouette:', '👥');
    retVal = this.replaceAll(retVal, ':speaking_head:', '🗣️');
    retVal = this.replaceAll(retVal, ':baby:', '👶');
    retVal = this.replaceAll(retVal, ':boy:', '👦');
    retVal = this.replaceAll(retVal, ':girl:', '👧');
    retVal = this.replaceAll(retVal, ':man:', '👨');
    retVal = this.replaceAll(retVal, ':woman:', '👩');
    retVal = this.replaceAll(retVal, ':older_man:', '👴');
    retVal = this.replaceAll(retVal, ':older_woman:', '👵');
    retVal = this.replaceAll(retVal, ':man_with_chinese_cap:', '👲');
    retVal = this.replaceAll(retVal, ':person_frowning:', '🙍');
    retVal = this.replaceAll(retVal, ':person_with_pouting_face:', '🙎');
    retVal = this.replaceAll(retVal, ':no_good:', '🙅');
    retVal = this.replaceAll(retVal, ':ok_woman:', '🙆');
    retVal = this.replaceAll(retVal, ':information_desk_person:', '💁');
    retVal = this.replaceAll(retVal, ':raising_hand:', '🙋');
    retVal = this.replaceAll(retVal, ':massage:', '💆');
    retVal = this.replaceAll(retVal, ':haircut:', '💇');
    retVal = this.replaceAll(retVal, ':walking:', '🚶');
    retVal = this.replaceAll(retVal, ':runner:', '🏃');
    retVal = this.replaceAll(retVal, ':dancer:', '💃');
    retVal = this.replaceAll(retVal, ':dancers:', '👯');
    retVal = this.replaceAll(retVal, ':couple:', '👫');
    retVal = this.replaceAll(retVal, ':two_men_holding_hands:', '👬');
    retVal = this.replaceAll(retVal, ':two_women_holding_hands:', '👭');
    retVal = this.replaceAll(retVal, ':bow:', '🙇');
    retVal = this.replaceAll(retVal, ':couplekiss:', '💏');
    retVal = this.replaceAll(retVal, ':couple_with_heart:', '💑');
    retVal = this.replaceAll(retVal, ':massage:', '💆');
    retVal = this.replaceAll(retVal, ':pedestrian:', '🚶');
    retVal = this.replaceAll(retVal, ':runner:', '🏃');
    retVal = this.replaceAll(retVal, ':woman_dancing:', '💃');
    retVal = this.replaceAll(retVal, ':man_dancing:', '🕺');
    retVal = this.replaceAll(retVal, ':dancer:', '💃');
    retVal = this.replaceAll(retVal, ':dancing_men:', '👯‍♂️');
    retVal = this.replaceAll(retVal, ':dancing_women:', '👯‍♀️');
    retVal = this.replaceAll(retVal, ':business_suit_levitating:', '🕴️');
    retVal = this.replaceAll(retVal, ':bride_with_veil:', '👰');
    retVal = this.replaceAll(retVal, ':man_in_tuxedo:', '🤵');
    retVal = this.replaceAll(retVal, ':pregnant_woman:', '🤰');
    retVal = this.replaceAll(retVal, ':breast_feeding:', '🤱');
    retVal = this.replaceAll(retVal, ':angel:', '👼');
    retVal = this.replaceAll(retVal, ':santa:', '🎅');
    retVal = this.replaceAll(retVal, ':mrs_claus:', '🤶');
    retVal = this.replaceAll(retVal, ':superhero:', '🦸');
    retVal = this.replaceAll(retVal, ':supervillain:', '🦹');
    retVal = this.replaceAll(retVal, ':mage:', '🧙');
    retVal = this.replaceAll(retVal, ':fairy:', '🧚');
    retVal = this.replaceAll(retVal, ':vampire:', '🧛');
    retVal = this.replaceAll(retVal, ':merperson:', '🧜');
    retVal = this.replaceAll(retVal, ':elf:', '🧝');
    retVal = this.replaceAll(retVal, ':genie:', '🧞');
    retVal = this.replaceAll(retVal, ':zombie:', '🧟');
    retVal = this.replaceAll(retVal, ':coat:', '🧥');
    retVal = this.replaceAll(retVal, ':scarf:', '🧣');
    retVal = this.replaceAll(retVal, ':gloves:', '🧤');
    retVal = this.replaceAll(retVal, ':socks:', '🧦');
    retVal = this.replaceAll(retVal, ':clown:', '🤡');
    retVal = this.replaceAll(retVal, ':robot:', '🤖');
    retVal = this.replaceAll(retVal, ':martial_arts_uniform:', '🥋');
    retVal = this.replaceAll(retVal, ':safety_vest:', '🦺');
    retVal = this.replaceAll(retVal, ':badge:', '📛');
    retVal = this.replaceAll(retVal, ':construction:', '🚧');
    retVal = this.replaceAll(retVal, ':rescue_worker_helmet:', '⛑️');
    retVal = this.replaceAll(retVal, ':prayer_beads:', '📿');
    retVal = this.replaceAll(retVal, ':lipstick:', '💄');
    retVal = this.replaceAll(retVal, ':ring:', '💍');
    retVal = this.replaceAll(retVal, ':gem:', '💎');
    retVal = this.replaceAll(retVal, ':mute:', '🔇');
    retVal = this.replaceAll(retVal, ':speaker:', '🔈');
    retVal = this.replaceAll(retVal, ':sound:', '🔉');
    retVal = this.replaceAll(retVal, ':loud_sound:', '🔊');
    retVal = this.replaceAll(retVal, ':loudspeaker:', '📢');
    retVal = this.replaceAll(retVal, ':mega:', '📣');
    retVal = this.replaceAll(retVal, ':bell:', '🔔');
    retVal = this.replaceAll(retVal, ':no_bell:', '🔕');
    retVal = this.replaceAll(retVal, ':musical_note:', '🎵');
    retVal = this.replaceAll(retVal, ':notes:', '🎶');
    retVal = this.replaceAll(retVal, ':studio_microphone:', '🎙️');
    retVal = this.replaceAll(retVal, ':level_slider:', '🎚️');
    retVal = this.replaceAll(retVal, ':control_knobs:', '🎛️');
    retVal = this.replaceAll(retVal, ':microphone:', '🎤');
    retVal = this.replaceAll(retVal, ':headphones:', '🎧');
    retVal = this.replaceAll(retVal, ':radio:', '📻');
    retVal = this.replaceAll(retVal, ':saxophone:', '🎷');
    retVal = this.replaceAll(retVal, ':guitar:', '🎸');
    retVal = this.replaceAll(retVal, ':musical_keyboard:', '🎹');
    retVal = this.replaceAll(retVal, ':trumpet:', '🎺');
    retVal = this.replaceAll(retVal, ':violin:', '🎻');
    retVal = this.replaceAll(retVal, ':drum:', '🥁');
    retVal = this.replaceAll(retVal, ':zzz:', '💤');
    retVal = this.replaceAll(retVal, ':wave:', '👋');
    retVal = this.replaceAll(retVal, ':raised_back_of_hand:', '🤚');
    retVal = this.replaceAll(retVal, ':hand_splayed:', '🖐️');
    retVal = this.replaceAll(retVal, ':raised_hand_with_fingers_splayed:', '🖐️');
    retVal = this.replaceAll(retVal, ':vulcan_salute:', '🖖');
    retVal = this.replaceAll(retVal, ':ok_hand:', '👌');
    retVal = this.replaceAll(retVal, ':pinching_hand:', '🤏');
    retVal = this.replaceAll(retVal, ':pinched_fingers:', '🤌');
    retVal = this.replaceAll(retVal, ':thumbsup:', '👍');
    retVal = this.replaceAll(retVal, ':thumbsdown:', '👎');
    retVal = this.replaceAll(retVal, ':fist:', '✊');
    retVal = this.replaceAll(retVal, ':left-facing_fist:', '🤛');
    retVal = this.replaceAll(retVal, ':right-facing_fist:', '🤜');
    retVal = this.replaceAll(retVal, ':clap:', '👏');
    retVal = this.replaceAll(retVal, ':raised_hands:', '🙌');
    retVal = this.replaceAll(retVal, ':open_hands:', '👐');
    retVal = this.replaceAll(retVal, ':handshake:', '🤝');
    retVal = this.replaceAll(retVal, ':pray:', '🙏');
    retVal = this.replaceAll(retVal, ':writing_hand:', '✍️');
    retVal = this.replaceAll(retVal, ':nail_care:', '💅');
    retVal = this.replaceAll(retVal, ':selfie:', '🤳');
    retVal = this.replaceAll(retVal, ':muscle:', '💪');
    retVal = this.replaceAll(retVal, ':mechanical_arm:', '🦾');
    retVal = this.replaceAll(retVal, ':mechanical_leg:', '🦿');
    retVal = this.replaceAll(retVal, ':leg:', '🦵');
    retVal = this.replaceAll(retVal, ':foot:', '🦶');
    retVal = this.replaceAll(retVal, ':ear:', '👂');
    retVal = this.replaceAll(retVal, ':nose:', '👃');
    retVal = this.replaceAll(retVal, ':brain:', '🧠');
    retVal = this.replaceAll(retVal, ':anatomical_heart:', '🫀');
    retVal = this.replaceAll(retVal, ':lungs:', '🫁');
    retVal = this.replaceAll(retVal, ':tooth:', '🦷');
    retVal = this.replaceAll(retVal, ':bone:', '🦴');
    retVal = this.replaceAll(retVal, ':eyes:', '👀');
    retVal = this.replaceAll(retVal, ':eye:', '👁️');
    retVal = this.replaceAll(retVal, ':tongue:', '👅');
    retVal = this.replaceAll(retVal, ':mouth:', '👄');
    retVal = this.replaceAll(retVal, ':baby:', '👶');
    retVal = this.replaceAll(retVal, ':child:', '🧒');
    retVal = this.replaceAll(retVal, ':boy:', '👦');
    retVal = this.replaceAll(retVal, ':girl:', '👧');
    retVal = this.replaceAll(retVal, ':adult:', '🧑');
    retVal = this.replaceAll(retVal, ':man:', '👨');
    retVal = this.replaceAll(retVal, ':woman:', '👩');
    retVal = this.replaceAll(retVal, ':older_adult:', '🧓');
    retVal = this.replaceAll(retVal, ':older_man:', '👴');
    retVal = this.replaceAll(retVal, ':older_woman:', '👵');
    retVal = this.replaceAll(retVal, ':person_frowning:', '🙍');
    retVal = this.replaceAll(retVal, ':person_with_pouting_face:', '🙎');
    retVal = this.replaceAll(retVal, ':person_with_folded_hands:', '🙏');
    retVal = this.replaceAll(retVal, ':rocket:', '🚀');
    retVal = this.replaceAll(retVal, ':helicopter:', '🚁');
    retVal = this.replaceAll(retVal, ':airplane:', '✈️');
    retVal = this.replaceAll(retVal, ':sailboat:', '⛵');
    retVal = this.replaceAll(retVal, ':motorboat:', '🛥️');
    retVal = this.replaceAll(retVal, ':speedboat:', '🚤');
    retVal = this.replaceAll(retVal, ':ferry:', '⛴️');
    retVal = this.replaceAll(retVal, ':ship:', '🚢');
    retVal = this.replaceAll(retVal, ':anchor:', '⚓');
    retVal = this.replaceAll(retVal, ':construction_site:', '🏗️');
    retVal = this.replaceAll(retVal, ':tent:', '⛺');
    retVal = this.replaceAll(retVal, ':factory:', '🏭');
    retVal = this.replaceAll(retVal, ':house:', '🏠');
    retVal = this.replaceAll(retVal, ':house_with_garden:', '🏡');
    retVal = this.replaceAll(retVal, ':office:', '🏢');
    retVal = this.replaceAll(retVal, ':post_office:', '🏣');
    retVal = this.replaceAll(retVal, ':european_post_office:', '🏤');
    retVal = this.replaceAll(retVal, ':hospital:', '🏥');
    retVal = this.replaceAll(retVal, ':bank:', '🏦');
    retVal = this.replaceAll(retVal, ':hotel:', '🏨');
    retVal = this.replaceAll(retVal, ':love_hotel:', '🏩');
    retVal = this.replaceAll(retVal, ':convenience_store:', '🏪');
    retVal = this.replaceAll(retVal, ':school:', '🏫');
    retVal = this.replaceAll(retVal, ':department_store:', '🏬');
    retVal = this.replaceAll(retVal, ':factory:', '🏭');
    retVal = this.replaceAll(retVal, ':japanese_castle:', '🏯');
    retVal = this.replaceAll(retVal, ':european_castle:', '🏰');
    retVal = this.replaceAll(retVal, ':wedding:', '💒');
    retVal = this.replaceAll(retVal, ':tokyo_tower:', '🗼');
    retVal = this.replaceAll(retVal, ':statue_of_liberty:', '🗽');
    retVal = this.replaceAll(retVal, ':church:', '⛪');
    retVal = this.replaceAll(retVal, ':mosque:', '🕌');
    retVal = this.replaceAll(retVal, ':synagogue:', '🕍');
    retVal = this.replaceAll(retVal, ':shinto_shrine:', '⛩️');
    retVal = this.replaceAll(retVal, ':kaaba:', '🕋');
    retVal = this.replaceAll(retVal, ':fountain:', '⛲');
    retVal = this.replaceAll(retVal, ':tent:', '⛺');
    retVal = this.replaceAll(retVal, ':foggy:', '🌁');
    retVal = this.replaceAll(retVal, ':night_with_stars:', '🌃');
    retVal = this.replaceAll(retVal, ':sunrise_over_mountains:', '🌄');
    retVal = this.replaceAll(retVal, ':sunrise:', '🌅');
    retVal = this.replaceAll(retVal, ':city_sunset:', '🌆');
    retVal = this.replaceAll(retVal, ':city_sunrise:', '🌇');
    retVal = this.replaceAll(retVal, ':bridge_at_night:', '🌉');
    retVal = this.replaceAll(retVal, ':hot_springs:', '♨️');
    retVal = this.replaceAll(retVal, ':carousel_horse:', '🎠');
    retVal = this.replaceAll(retVal, ':ferris_wheel:', '🎡');
    retVal = this.replaceAll(retVal, ':roller_coaster:', '🎢');
    retVal = this.replaceAll(retVal, ':barber_pole:', '💈');
    retVal = this.replaceAll(retVal, ':circus_tent:', '🎪');
    retVal = this.replaceAll(retVal, ':steam_locomotive:', '🚂');
    retVal = this.replaceAll(retVal, ':railway_car:', '🚃');
    retVal = this.replaceAll(retVal, ':bullettrain_side:', '🚄');
    retVal = this.replaceAll(retVal, ':bullettrain_front:', '🚅');
    retVal = this.replaceAll(retVal, ':train2:', '🚆');
    retVal = this.replaceAll(retVal, ':metro:', '🚇');
    retVal = this.replaceAll(retVal, ':light_rail:', '🚈');
    retVal = this.replaceAll(retVal, ':station:', '🚉');
    retVal = this.replaceAll(retVal, ':tram:', '🚊');
    retVal = this.replaceAll(retVal, ':monorail:', '🚝');
    retVal = this.replaceAll(retVal, ':mountain_railway:', '🚞');
    retVal = this.replaceAll(retVal, ':train:', '🚋');
    retVal = this.replaceAll(retVal, ':bus:', '🚌');
    retVal = this.replaceAll(retVal, ':oncoming_bus:', '🚍');
    retVal = this.replaceAll(retVal, ':trolleybus:', '🚎');
    retVal = this.replaceAll(retVal, ':minibus:', '🚐');
    retVal = this.replaceAll(retVal, ':ambulance:', '🚑');
    retVal = this.replaceAll(retVal, ':fire_engine:', '🚒');
    retVal = this.replaceAll(retVal, ':police_car:', '🚓');
    retVal = this.replaceAll(retVal, ':oncoming_police_car:', '🚔');
    retVal = this.replaceAll(retVal, ':taxi:', '🚕');
    retVal = this.replaceAll(retVal, ':oncoming_taxi:', '🚖');
    retVal = this.replaceAll(retVal, ':car:', '🚗');
    retVal = this.replaceAll(retVal, ':oncoming_automobile:', '🚘');
    retVal = this.replaceAll(retVal, ':blue_car:', '🚙');
    retVal = this.replaceAll(retVal, ':truck:', '🚚');
    retVal = this.replaceAll(retVal, ':articulated_lorry:', '🚛');
    retVal = this.replaceAll(retVal, ':tractor:', '🚜');
    retVal = this.replaceAll(retVal, ':racing_car:', '🏎️');
    retVal = this.replaceAll(retVal, ':motorcycle:', '🏍️');
    retVal = this.replaceAll(retVal, ':motor_scooter:', '🛵');
    retVal = this.replaceAll(retVal, ':bike:', '🚲');
    retVal = this.replaceAll(retVal, ':kick_scooter:', '🛴');
    retVal = this.replaceAll(retVal, ':skateboard:', '🛹');
    retVal = this.replaceAll(retVal, ':busstop:', '🚏');
    retVal = this.replaceAll(retVal, ':motorway:', '🛣️');
    retVal = this.replaceAll(retVal, ':railway_track:', '🛤️');
    retVal = this.replaceAll(retVal, ':oil_drum:', '🛢️');
    retVal = this.replaceAll(retVal, ':fuelpump:', '⛽');
    retVal = this.replaceAll(retVal, ':rotating_light:', '🚨');
    retVal = this.replaceAll(retVal, ':traffic_light:', '🚥');
    retVal = this.replaceAll(retVal, ':vertical_traffic_light:', '🚦');
    retVal = this.replaceAll(retVal, ':stop_sign:', '🛑');
    retVal = this.replaceAll(retVal, ':construction:', '🚧');
    retVal = this.replaceAll(retVal, ':anchor:', '⚓');
    retVal = this.replaceAll(retVal, ':rocket:', '🚀');
    retVal = this.replaceAll(retVal, ':hourglass_flowing_sand:', '⏳');
    retVal = this.replaceAll(retVal, ':hourglass:', '⌛');
    retVal = this.replaceAll(retVal, ':watch:', '⌚');
    retVal = this.replaceAll(retVal, ':alarm_clock:', '⏰');
    retVal = this.replaceAll(retVal, ':stopwatch:', '⏱️');
    retVal = this.replaceAll(retVal, ':timer_clock:', '⏲️');
    retVal = this.replaceAll(retVal, ':mantelpiece_clock:', '🕰️');
    retVal = this.replaceAll(retVal, ':calendar:', '📅');
    retVal = this.replaceAll(retVal, ':spiral_calendar:', '🗓️');
    retVal = this.replaceAll(retVal, ':card_index:', '📇');
    retVal = this.replaceAll(retVal, ':chart_with_upwards_trend:', '📈');
    retVal = this.replaceAll(retVal, ':chart_with_downwards_trend:', '📉');
    retVal = this.replaceAll(retVal, ':bar_chart:', '📊');
    retVal = this.replaceAll(retVal, ':clipboard:', '📋');
    retVal = this.replaceAll(retVal, ':pushpin:', '📌');
    retVal = this.replaceAll(retVal, ':round_pushpin:', '📍');
    retVal = this.replaceAll(retVal, ':paperclip:', '📎');
    retVal = this.replaceAll(retVal, ':linked_paperclips:', '🖇️');
    retVal = this.replaceAll(retVal, ':straight_ruler:', '📏');
    retVal = this.replaceAll(retVal, ':triangular_ruler:', '📐');
    retVal = this.replaceAll(retVal, ':scissors:', '✂️');
    retVal = this.replaceAll(retVal, ':card_file_box:', '🗃️');
    retVal = this.replaceAll(retVal, ':file_cabinet:', '🗄️');
    retVal = this.replaceAll(retVal, ':wastebasket:', '🗑️');
    retVal = this.replaceAll(retVal, ':lock:', '🔒');
    retVal = this.replaceAll(retVal, ':unlock:', '🔓');
    retVal = this.replaceAll(retVal, ':locked_with_key:', '🔐');
    retVal = this.replaceAll(retVal, ':key:', '🔑');
    retVal = this.replaceAll(retVal, ':old_key:', '🗝️');
    retVal = this.replaceAll(retVal, ':hammer:', '🔨');
    retVal = this.replaceAll(retVal, ':axe:', '🪓');
    retVal = this.replaceAll(retVal, ':pick:', '⛏️');
    retVal = this.replaceAll(retVal, ':hammer_and_pick:', '⚒️');
    retVal = this.replaceAll(retVal, ':hammer_and_wrench:', '🛠️');
    retVal = this.replaceAll(retVal, ':dagger:', '🗡️');
    retVal = this.replaceAll(retVal, ':crossed_swords:', '⚔️');
    retVal = this.replaceAll(retVal, ':gun:', '🔫');
    retVal = this.replaceAll(retVal, ':boomerang:', '🪃');
    retVal = this.replaceAll(retVal, ':bow_and_arrow:', '🏹');
    retVal = this.replaceAll(retVal, ':shield:', '🛡️');
    retVal = this.replaceAll(retVal, ':carpentry_saw:', '🪚');
    retVal = this.replaceAll(retVal, ':wrench:', '🔧');
    retVal = this.replaceAll(retVal, ':screwdriver:', '🪛');
    retVal = this.replaceAll(retVal, ':nut_and_bolt:', '🔩');
    retVal = this.replaceAll(retVal, ':gear:', '⚙️');
    retVal = this.replaceAll(retVal, ':clamp:', '🗜️');
    retVal = this.replaceAll(retVal, ':balance_scale:', '⚖️');
    retVal = this.replaceAll(retVal, ':probing_cane:', '🦯');
    retVal = this.replaceAll(retVal, ':link:', '🔗');
    retVal = this.replaceAll(retVal, ':chains:', '⛓️');
    retVal = this.replaceAll(retVal, ':hook:', '🪝');
    retVal = this.replaceAll(retVal, ':toolbox:', '🧰');
    retVal = this.replaceAll(retVal, ':magnet:', '🧲');
    retVal = this.replaceAll(retVal, ':ladder:', '🪜');
    retVal = this.replaceAll(retVal, ':alembic:', '⚗️');
    retVal = this.replaceAll(retVal, ':test_tube:', '🧪');
    retVal = this.replaceAll(retVal, ':petri_dish:', '🧫');
    retVal = this.replaceAll(retVal, ':dna:', '🧬');
    retVal = this.replaceAll(retVal, ':microscope:', '🔬');
    retVal = this.replaceAll(retVal, ':telescope:', '🔭');
    retVal = this.replaceAll(retVal, ':satellite:', '🛰️');
    retVal = this.replaceAll(retVal, ':syringe:', '💉');
    retVal = this.replaceAll(retVal, ':drop_of_blood:', '🩸');
    retVal = this.replaceAll(retVal, ':pill:', '💊');
    retVal = this.replaceAll(retVal, ':adhesive_bandage:', '🩹');
    retVal = this.replaceAll(retVal, ':stethoscope:', '🩺');
    retVal = this.replaceAll(retVal, ':door:', '🚪');
    retVal = this.replaceAll(retVal, ':bed:', '🛏️');
    retVal = this.replaceAll(retVal, ':couch_and_lamp:', '🛋️');
    retVal = this.replaceAll(retVal, ':chair:', '🪑');
    retVal = this.replaceAll(retVal, ':toilet:', '🚽');
    retVal = this.replaceAll(retVal, ':shower:', '🚿');
    retVal = this.replaceAll(retVal, ':bathtub:', '🛁');
    retVal = this.replaceAll(retVal, ':razor:', '🪒');
    retVal = this.replaceAll(retVal, ':lotion_bottle:', '🧴');
    retVal = this.replaceAll(retVal, ':safety_pin:', '🧷');
    retVal = this.replaceAll(retVal, ':broom:', '🧹');
    retVal = this.replaceAll(retVal, ':basket:', '🧺');
    retVal = this.replaceAll(retVal, ':roll_of_paper:', '🧻');
    retVal = this.replaceAll(retVal, ':soap:', '🧼');
    retVal = this.replaceAll(retVal, ':sponge:', '🧽');
    retVal = this.replaceAll(retVal, ':fire_extinguisher:', '🧯');
    retVal = this.replaceAll(retVal, ':shopping_cart:', '🛒');
    retVal = this.replaceAll(retVal, ':cigarette:', '🚬');
    retVal = this.replaceAll(retVal, ':coffin:', '⚰️');
    retVal = this.replaceAll(retVal, ':funeral_urn:', '⚱️');
    retVal = this.replaceAll(retVal, ':moai:', '🗿');
    retVal = this.replaceAll(retVal, ':atm:', '🏧');
    retVal = this.replaceAll(retVal, ':put_litter_in_its_place:', '🚮');
    retVal = this.replaceAll(retVal, ':potable_water:', '🚰');
    retVal = this.replaceAll(retVal, ':wheelchair:', '♿');
    retVal = this.replaceAll(retVal, ':mens:', '🚹');
    retVal = this.replaceAll(retVal, ':womens:', '🚺');
    retVal = this.replaceAll(retVal, ':restroom:', '🚻');
    retVal = this.replaceAll(retVal, ':baby_symbol:', '🚼');
    retVal = this.replaceAll(retVal, ':wc:', '🚾');
    retVal = this.replaceAll(retVal, ':passport_control:', '🛂');
    retVal = this.replaceAll(retVal, ':customs:', '🛃');
    retVal = this.replaceAll(retVal, ':baggage_claim:', '🛄');
    retVal = this.replaceAll(retVal, ':left_luggage:', '🛅');
    retVal = this.replaceAll(retVal, ':warning:', '⚠️');
    retVal = this.replaceAll(retVal, ':children_crossing:', '🚸');
    retVal = this.replaceAll(retVal, ':no_entry:', '⛔');
    retVal = this.replaceAll(retVal, ':no_entry_sign:', '🚫');
    retVal = this.replaceAll(retVal, ':no_bicycles:', '🚳');
    retVal = this.replaceAll(retVal, ':no_smoking:', '🚭');
    retVal = this.replaceAll(retVal, ':do_not_litter:', '🚯');
    retVal = this.replaceAll(retVal, ':non-potable_water:', '🚱');
    retVal = this.replaceAll(retVal, ':no_pedestrians:', '🚷');
    retVal = this.replaceAll(retVal, ':no_mobile_phones:', '📵');
    retVal = this.replaceAll(retVal, ':underage:', '🔞');
    retVal = this.replaceAll(retVal, ':radioactive:', '☢️');
    retVal = this.replaceAll(retVal, ':biohazard:', '☣️');
    retVal = this.replaceAll(retVal, ':arrow_up:', '⬆️');
    retVal = this.replaceAll(retVal, ':arrow_upper_right:', '↗️');
    retVal = this.replaceAll(retVal, ':arrow_right:', '➡️');
    retVal = this.replaceAll(retVal, ':arrow_lower_right:', '↘️');
    retVal = this.replaceAll(retVal, ':arrow_down:', '⬇️');
    retVal = this.replaceAll(retVal, ':arrow_lower_left:', '↙️');
    retVal = this.replaceAll(retVal, ':arrow_left:', '⬅️');
    retVal = this.replaceAll(retVal, ':arrow_upper_left:', '↖️');
    retVal = this.replaceAll(retVal, ':arrow_up_down:', '↕️');
    retVal = this.replaceAll(retVal, ':left_right_arrow:', '↔️');
    retVal = this.replaceAll(retVal, ':leftwards_arrow_with_hook:', '↩️');
    retVal = this.replaceAll(retVal, ':arrow_right_hook:', '↪️');
    retVal = this.replaceAll(retVal, ':arrow_heading_up:', '⤴️');
    retVal = this.replaceAll(retVal, ':arrow_heading_down:', '⤵️');
    retVal = this.replaceAll(retVal, ':arrows_clockwise:', '🔃');
    retVal = this.replaceAll(retVal, ':arrows_counterclockwise:', '🔄');
    retVal = this.replaceAll(retVal, ':back:', '🔙');
    retVal = this.replaceAll(retVal, ':end:', '🔚');
    retVal = this.replaceAll(retVal, ':on:', '🔛');
    retVal = this.replaceAll(retVal, ':soon:', '🔜');
    retVal = this.replaceAll(retVal, ':top:', '🔝');
    retVal = this.replaceAll(retVal, ':place_of_worship:', '🛐');
    retVal = this.replaceAll(retVal, ':atom_symbol:', '⚛️');
    retVal = this.replaceAll(retVal, ':om:', '🕉️');
    retVal = this.replaceAll(retVal, ':star_of_david:', '✡️');
    retVal = this.replaceAll(retVal, ':wheel_of_dharma:', '☸️');
    retVal = this.replaceAll(retVal, ':yin_yang:', '☯️');
    retVal = this.replaceAll(retVal, ':latin_cross:', '✝️');
    retVal = this.replaceAll(retVal, ':orthodox_cross:', '☦️');
    retVal = this.replaceAll(retVal, ':star_and_crescent:', '☪️');
    retVal = this.replaceAll(retVal, ':peace:', '☮️');
    retVal = this.replaceAll(retVal, ':menorah:', '🕎');
    retVal = this.replaceAll(retVal, ':six_pointed_star:', '🔯');
    retVal = this.replaceAll(retVal, ':aries:', '♈');
    retVal = this.replaceAll(retVal, ':taurus:', '♉');
    retVal = this.replaceAll(retVal, ':gemini:', '♊');
    retVal = this.replaceAll(retVal, ':cancer:', '♋');
    retVal = this.replaceAll(retVal, ':leo:', '♌');
    retVal = this.replaceAll(retVal, ':virgo:', '♍');
    retVal = this.replaceAll(retVal, ':libra:', '♎');
    retVal = this.replaceAll(retVal, ':scorpius:', '♏');
    retVal = this.replaceAll(retVal, ':sagittarius:', '♐');
    retVal = this.replaceAll(retVal, ':capricorn:', '♑');
    retVal = this.replaceAll(retVal, ':aquarius:', '♒');
    retVal = this.replaceAll(retVal, ':pisces:', '♓');
    retVal = this.replaceAll(retVal, ':ophiuchus:', '⛎');
    retVal = this.replaceAll(retVal, ':twisted_rightwards_arrows:', '🔀');
    retVal = this.replaceAll(retVal, ':repeat:', '🔁');
    retVal = this.replaceAll(retVal, ':repeat_one:', '🔂');
    retVal = this.replaceAll(retVal, ':arrow_forward:', '▶️');
    retVal = this.replaceAll(retVal, ':fast_forward:', '⏩');
    retVal = this.replaceAll(retVal, ':next_track_button:', '⏭️');
    retVal = this.replaceAll(retVal, ':play_or_pause_button:', '⏯️');
    retVal = this.replaceAll(retVal, ':arrow_backward:', '◀️');
    retVal = this.replaceAll(retVal, ':rewind:', '⏪');
    retVal = this.replaceAll(retVal, ':previous_track_button:', '⏮️');
    retVal = this.replaceAll(retVal, ':arrow_up_small:', '🔼');
    retVal = this.replaceAll(retVal, ':arrow_double_up:', '⏫');
    retVal = this.replaceAll(retVal, ':arrow_down_small:', '🔽');
    retVal = this.replaceAll(retVal, ':arrow_double_down:', '⏬');
    retVal = this.replaceAll(retVal, ':pause_button:', '⏸️');
    retVal = this.replaceAll(retVal, ':stop_button:', '⏹️');
    retVal = this.replaceAll(retVal, ':record_button:', '⏺️');
    retVal = this.replaceAll(retVal, ':eject_button:', '⏏️');
    retVal = this.replaceAll(retVal, ':cinema:', '🎦');
    retVal = this.replaceAll(retVal, ':low_brightness:', '🔅');
    retVal = this.replaceAll(retVal, ':high_brightness:', '🔆');
    retVal = this.replaceAll(retVal, ':signal_strength:', '📶');
    retVal = this.replaceAll(retVal, ':vibration_mode:', '📳');
    retVal = this.replaceAll(retVal, ':mobile_phone_off:', '📴');
    retVal = this.replaceAll(retVal, ':female_sign:', '♀️');
    retVal = this.replaceAll(retVal, ':male_sign:', '♂️');
    retVal = this.replaceAll(retVal, ':transgender_symbol:', '⚧️');
    retVal = this.replaceAll(retVal, ':heavy_multiplication_x:', '✖️');
    retVal = this.replaceAll(retVal, ':heavy_plus_sign:', '➕');
    retVal = this.replaceAll(retVal, ':heavy_minus_sign:', '➖');
    retVal = this.replaceAll(retVal, ':heavy_division_sign:', '➗');
    retVal = this.replaceAll(retVal, ':infinity:', '♾️');
    retVal = this.replaceAll(retVal, ':bangbang:', '‼️');
    retVal = this.replaceAll(retVal, ':interrobang:', '⁉️');
    retVal = this.replaceAll(retVal, ':question:', '❓');
    retVal = this.replaceAll(retVal, ':grey_question:', '❔');
    retVal = this.replaceAll(retVal, ':grey_exclamation:', '❕');
    retVal = this.replaceAll(retVal, ':exclamation:', '❗');
    retVal = this.replaceAll(retVal, ':wavy_dash:', '〰️');
    retVal = this.replaceAll(retVal, ':currency_exchange:', '💱');
    retVal = this.replaceAll(retVal, ':heavy_dollar_sign:', '💲');
    retVal = this.replaceAll(retVal, ':medical_symbol:', '⚕️');
    retVal = this.replaceAll(retVal, ':recycle:', '♻️');
    retVal = this.replaceAll(retVal, ':fleur_de_lis:', '⚜️');
    retVal = this.replaceAll(retVal, ':trident:', '🔱');
    retVal = this.replaceAll(retVal, ':name_badge:', '📛');
    retVal = this.replaceAll(retVal, ':beginner:', '🔰');
    retVal = this.replaceAll(retVal, ':o:', '⭕');
    retVal = this.replaceAll(retVal, ':white_check_mark:', '✅');
    retVal = this.replaceAll(retVal, ':ballot_box_with_check:', '☑️');
    retVal = this.replaceAll(retVal, ':heavy_check_mark:', '✔️');
    retVal = this.replaceAll(retVal, ':x:', '❌');
    retVal = this.replaceAll(retVal, ':negative_squared_cross_mark:', '❎');
    retVal = this.replaceAll(retVal, ':curly_loop:', '➰');
    retVal = this.replaceAll(retVal, ':loop:', '➿');
    retVal = this.replaceAll(retVal, ':part_alternation_mark:', '〽️');
    retVal = this.replaceAll(retVal, ':eight_spoked_asterisk:', '✳️');
    retVal = this.replaceAll(retVal, ':eight_pointed_black_star:', '✴️');
    retVal = this.replaceAll(retVal, ':sparkle:', '❇️');
    retVal = this.replaceAll(retVal, ':information_source:', 'ℹ️');
    retVal = this.replaceAll(retVal, ':eject:', '⏏️');
    retVal = this.replaceAll(retVal, ':hash:', '#️⃣');
    retVal = this.replaceAll(retVal, ':asterisk:', '*️⃣');
    retVal = this.replaceAll(retVal, ':keycap_star:', '*️⃣');
    retVal = this.replaceAll(retVal, ':keycap_0:', '0️⃣');
    retVal = this.replaceAll(retVal, ':keycap_1:', '1️⃣');
    retVal = this.replaceAll(retVal, ':keycap_2:', '2️⃣');
    retVal = this.replaceAll(retVal, ':keycap_3:', '3️⃣');
    retVal = this.replaceAll(retVal, ':keycap_4:', '4️⃣');
    retVal = this.replaceAll(retVal, ':keycap_5:', '5️⃣');
    retVal = this.replaceAll(retVal, ':keycap_6:', '6️⃣');
    retVal = this.replaceAll(retVal, ':keycap_7:', '7️⃣');
    retVal = this.replaceAll(retVal, ':keycap_8:', '8️⃣');
    retVal = this.replaceAll(retVal, ':keycap_9:', '9️⃣');
    retVal = this.replaceAll(retVal, ':capital_abcd:', '🔠');
    retVal = this.replaceAll(retVal, ':abcd:', '🔡');
    retVal = this.replaceAll(retVal, ':abc:', '🔤');
    retVal = this.replaceAll(retVal, ':information_desk_person:', '💁');
    retVal = this.replaceAll(retVal, ':ok_woman:', '🙆');
    retVal = this.replaceAll(retVal, ':raising_hand:', '🙋');
    retVal = this.replaceAll(retVal, ':woman_facepalming:', '🤦‍♀️');
    retVal = this.replaceAll(retVal, ':man_facepalming:', '🤦‍♂️');
    retVal = this.replaceAll(retVal, ':woman_shrugging:', '🤷‍♀️');
    retVal = this.replaceAll(retVal, ':man_shrugging:', '🤷‍♂️');
    retVal = this.replaceAll(retVal, ':pouting_woman:', '🙎‍♀️');
    retVal = this.replaceAll(retVal, ':pouting_man:', '🙎‍♂️');
    retVal = this.replaceAll(retVal, ':frowning_woman:', '🙍‍♀️');
    retVal = this.replaceAll(retVal, ':frowning_man:', '🙍‍♂️');
    retVal = this.replaceAll(retVal, ':no_good_woman:', '🙅‍♀️');
    retVal = this.replaceAll(retVal, ':no_good_man:', '🙅‍♂️');
    retVal = this.replaceAll(retVal, ':ok_person:', '🙆‍♂️');
    retVal = this.replaceAll(retVal, ':raising_hand_man:', '🙋‍♂️');
    retVal = this.replaceAll(retVal, ':raising_hand_woman:', '🙋‍♀️');
    retVal = this.replaceAll(retVal, ':person_facepalming:', '🤦');
    retVal = this.replaceAll(retVal, ':man_facepalming:', '🤦‍♂️');
    retVal = this.replaceAll(retVal, ':woman_facepalming:', '🤦‍♀️');
    retVal = this.replaceAll(retVal, ':person_shrugging:', '🤷');
    retVal = this.replaceAll(retVal, ':man_shrugging:', '🤷‍♂️');
    retVal = this.replaceAll(retVal, ':woman_shrugging:', '🤷‍♀️');
    retVal = this.replaceAll(retVal, ':pouting_man:', '🙎‍♂️');
    retVal = this.replaceAll(retVal, ':pouting_woman:', '🙎‍♀️');
    retVal = this.replaceAll(retVal, ':frowning_man:', '🙍‍♂️');
    retVal = this.replaceAll(retVal, ':no_good_man:', '🙅‍♂️');
    retVal = this.replaceAll(retVal, ':no_good_woman:', '🙅‍♀️');
    retVal = this.replaceAll(retVal, ':ok_man:', '🙆‍♂️');
    retVal = this.replaceAll(retVal, ':person_with_pouting_face:', '🙎');
    retVal = this.replaceAll(retVal, ':person_frowning:', '🙍');
    retVal = this.replaceAll(retVal, ':person_with_folded_hands:', '🙏');
    retVal = this.replaceAll(retVal, ':haircut_woman:', '💇‍♀️');
    retVal = this.replaceAll(retVal, ':haircut_man:', '💇‍♂️');
    retVal = this.replaceAll(retVal, ':massage_woman:', '💆‍♀️');
    retVal = this.replaceAll(retVal, ':massage_man:', '💆‍♂️');
    retVal = this.replaceAll(retVal, ':woman_in_steamy_room:', '🧖‍♀️');
    retVal = this.replaceAll(retVal, ':man_in_steamy_room:', '🧖‍♂️');
    retVal = this.replaceAll(retVal, ':couple_with_heart_woman_man:', '💑');
    retVal = this.replaceAll(retVal, ':couple_with_heart_man_man:', '👨‍❤️‍👨');
    retVal = this.replaceAll(retVal, ':couple_with_heart_woman_woman:', '👩‍❤️‍👩');
    retVal = this.replaceAll(retVal, ':couplekiss_man_woman:', '💏');
    retVal = this.replaceAll(retVal, ':couplekiss_man_man:', '👨‍❤️‍💋‍👨');
    retVal = this.replaceAll(retVal, ':couplekiss_woman_woman:', '👩‍❤️‍💋‍👩');
    retVal = this.replaceAll(retVal, ':family_man_woman_boy:', '👪');
    retVal = this.replaceAll(retVal, ':family_man_woman_girl:', '👨‍👩‍👧');
    retVal = this.replaceAll(retVal, ':family_man_woman_girl_boy:', '👨‍👩‍👧‍👦');
    retVal = this.replaceAll(retVal, ':family_man_woman_boy_boy:', '👨‍👩‍👦‍👦');
    retVal = this.replaceAll(retVal, ':family_man_woman_girl_girl:', '👨‍👩‍👧‍👧');
    retVal = this.replaceAll(retVal, ':family_man_man_boy:', '👨‍👨‍👦');
    retVal = this.replaceAll(retVal, ':family_man_man_girl:', '👨‍👨‍👧');
    retVal = this.replaceAll(retVal, ':family_man_man_girl_boy:', '👨‍👨‍👧‍👦');
    retVal = this.replaceAll(retVal, ':family_man_man_boy_boy:', '👨‍👨‍👦‍👦');
    retVal = this.replaceAll(retVal, ':family_man_man_girl_girl:', '👨‍👨‍👧‍👧');
    retVal = this.replaceAll(retVal, ':family_woman_woman_boy:', '👩‍👩‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_woman_girl:', '👩‍');
    retVal = this.replaceAll(retVal, ':family_woman_woman_girl_boy:', '👩‍👩‍👧‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_woman_boy_boy:', '👩‍👩‍👦‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_woman_girl_girl:', '👩‍👩‍👧‍👧');
    retVal = this.replaceAll(retVal, ':family_man_boy:', '👨‍👦');
    retVal = this.replaceAll(retVal, ':family_man_girl:', '👨‍👧');
    retVal = this.replaceAll(retVal, ':family_man_girl_boy:', '👨‍👧‍👦');
    retVal = this.replaceAll(retVal, ':family_man_boy_boy:', '👨‍👦‍👦');
    retVal = this.replaceAll(retVal, ':family_man_girl_girl:', '👨‍👧‍👧');
    retVal = this.replaceAll(retVal, ':family_woman_boy:', '👩‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_girl:', '👩‍👧');
    retVal = this.replaceAll(retVal, ':family_woman_girl_boy:', '👩‍👧‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_boy_boy:', '👩‍👦‍👦');
    retVal = this.replaceAll(retVal, ':family_woman_girl_girl:', '👩‍👧‍👧');
    retVal = this.replaceAll(retVal, ':man-boy-boy:', '👨‍👦‍👦');
    retVal = this.replaceAll(retVal, ':man-boy:', '👨‍👦');
    retVal = this.replaceAll(retVal, ':man-girl:', '👨‍👧');
    retVal = this.replaceAll(retVal, ':man-girl-boy:', '👨‍👧‍👦');
    retVal = this.replaceAll(retVal, ':man-girl-girl:', '👨‍👧‍👧');
    retVal = this.replaceAll(retVal, ':woman-boy-boy:', '👩‍👦‍👦');
    retVal = this.replaceAll(retVal, ':woman-boy:', '👩‍👦');
    retVal = this.replaceAll(retVal, ':woman-girl:', '👩‍👧');
    retVal = this.replaceAll(retVal, ':woman-girl-boy:', '👩‍👧‍👦');
    retVal = this.replaceAll(retVal, ':woman-girl-girl:', '👩‍👧‍👧');
    retVal = this.replaceAll(retVal, ':speaking_head:', '🗣️');
    retVal = this.replaceAll(retVal, ':bust_in_silhouette:', '👤');
    retVal = this.replaceAll(retVal, ':busts_in_silhouette:', '👥');
    retVal = this.replaceAll(retVal, ':woman:', '👩');
    retVal = this.replaceAll(retVal, ':older_adult:', '🧓');
    retVal = this.replaceAll(retVal, ':older_man:', '👴');
    retVal = this.replaceAll(retVal, ':older_woman:', '👵');
    retVal = this.replaceAll(retVal, ':man_with_chinese_cap:', '👲');
    retVal = this.replaceAll(retVal, ':woman_wearing_turban:', '👳‍♀️');
    retVal = this.replaceAll(retVal, ':man_wearing_turban:', '👳‍♂️');
    retVal = this.replaceAll(retVal, ':woman_police_officer:', '👮‍♀️');
    retVal = this.replaceAll(retVal, ':man_police_officer:', '👮‍♂️');
    retVal = this.replaceAll(retVal, ':construction_worker_woman:', '👷‍♀️');
    retVal = this.replaceAll(retVal, ':construction_worker_man:', '👷‍♂️');
    retVal = this.replaceAll(retVal, ':guardsman:', '💂');
    retVal = this.replaceAll(retVal, ':woman_guard:', '💂‍♀️');
    retVal = this.replaceAll(retVal, ':man_guard:', '💂‍♂️');
    retVal = this.replaceAll(retVal, ':female_detective:', '🕵️‍♀️');
    retVal = this.replaceAll(retVal, ':male_detective:', '🕵️‍♂️');
    retVal = this.replaceAll(retVal, ':woman_health_worker:', '👩‍⚕️');
    retVal = this.replaceAll(retVal, ':man_health_worker:', '👨‍⚕️');
    retVal = this.replaceAll(retVal, ':woman_farmer:', '👩‍🌾');
    retVal = this.replaceAll(retVal, ':man_farmer:', '👨‍🌾');
    retVal = this.replaceAll(retVal, ':woman_cook:', '👩‍🍳');
    retVal = this.replaceAll(retVal, ':man_cook:', '👨‍🍳');
    retVal = this.replaceAll(retVal, ':woman_student:', '👩‍🎓');
    retVal = this.replaceAll(retVal, ':man_student:', '👨‍🎓');
    retVal = this.replaceAll(retVal, ':woman_teacher:', '👩‍🏫');
    retVal = this.replaceAll(retVal, ':man_teacher:', '👨‍🏫');
    retVal = this.replaceAll(retVal, ':woman_singer:', '👩‍🎤');
    retVal = this.replaceAll(retVal, ':man_singer:', '👨‍🎤');
    retVal = this.replaceAll(retVal, ':woman_artist:', '👩‍🎨');
    retVal = this.replaceAll(retVal, ':man_artist:', '👨‍🎨');
    retVal = this.replaceAll(retVal, ':woman_pilot:', '👩‍✈️');
    retVal = this.replaceAll(retVal, ':man_pilot:', '👨‍✈️');
    retVal = this.replaceAll(retVal, ':woman_astronaut:', '👩‍🚀');
    retVal = this.replaceAll(retVal, ':man_astronaut:', '👨‍🚀');
    retVal = this.replaceAll(retVal, ':woman_firefighter:', '👩‍🚒');
    retVal = this.replaceAll(retVal, ':man_firefighter:', '👨‍🚒');
    retVal = this.replaceAll(retVal, ':cop:', '👮');
    retVal = this.replaceAll(retVal, ':sleuth_or_spy:', '🕵️');
    retVal = this.replaceAll(retVal, ':construction_worker:', '👷');
    retVal = this.replaceAll(retVal, ':man_with_turban:', '👳');
    retVal = this.replaceAll(retVal, ':person_with_blond_hair:', '👱');
    retVal = this.replaceAll(retVal, ':santa:', '🎅');
    retVal = this.replaceAll(retVal, ':angel:', '👼');
    retVal = this.replaceAll(retVal, ':princess:', '👸');
    retVal = this.replaceAll(retVal, ':bride_with_veil:', '👰');
    retVal = this.replaceAll(retVal, ':walking:', '🚶');
    retVal = this.replaceAll(retVal, ':runner:', '🏃');
    retVal = this.replaceAll(retVal, ':dancer:', '💃');
    retVal = this.replaceAll(retVal, ':person_with_pouting_face:', '🙎');
    retVal = this.replaceAll(retVal, ':person_shrugging:', '🤷');
    retVal = this.replaceAll(retVal, ':person_cartwheeling:', '🤸');
    retVal = this.replaceAll(retVal, ':people_with_bunny_ears_partying:', '👯');
    retVal = this.replaceAll(retVal, ':person_bowing:', '🙇');
    retVal = this.replaceAll(retVal, ':person_tipping_hand:', '💁');
    retVal = this.replaceAll(retVal, ':person_raising_hand:', '🙋');
    retVal = this.replaceAll(retVal, ':person_facepalming:', '🤦');
    retVal = this.replaceAll(retVal, ':person_wearing_turban:', '👳');
    retVal = this.replaceAll(retVal, ':person_with_skullcap:', '👲');
    retVal = this.replaceAll(retVal, ':woman_with_headscarf:', '🧕');
    retVal = this.replaceAll(retVal, ':man_in_tuxedo:', '🤵');
    retVal = this.replaceAll(retVal, ':bride_with_veil:', '👰');
    retVal = this.replaceAll(retVal, ':pregnant_woman:', '🤰');
    retVal = this.replaceAll(retVal, ':breast-feeding:', '🤱');
    retVal = this.replaceAll(retVal, ':baby_angel:', '👼');
    retVal = this.replaceAll(retVal, ':Santa Claus:', '🎅');
    retVal = this.replaceAll(retVal, ':Mrs. Claus:', '🤶');
    retVal = this.replaceAll(retVal, ':superhero:', '🦸');
    retVal = this.replaceAll(retVal, ':superhero_man:', '🦸‍♂️');
    retVal = this.replaceAll(retVal, ':superhero_woman:', '🦸‍♀️');
    retVal = this.replaceAll(retVal, ':supervillain:', '🦹');
    retVal = this.replaceAll(retVal, ':supervillain_man:', '🦹‍♂️');
    retVal = this.replaceAll(retVal, ':supervillain_woman:', '🦹‍♀️');
    retVal = this.replaceAll(retVal, ':mage:', '🧙');
    retVal = this.replaceAll(retVal, ':mage_man:', '🧙‍♂️');
    retVal = this.replaceAll(retVal, ':mage_woman:', '🧙‍♀️');
    retVal = this.replaceAll(retVal, ':fairy:', '🧚');
    retVal = this.replaceAll(retVal, ':fairy_man:', '🧚‍♂️');
    retVal = this.replaceAll(retVal, ':fairy_woman:', '🧚‍♀️');
    retVal = this.replaceAll(retVal, ':vampire:', '🧛');
    retVal = this.replaceAll(retVal, ':vampire_man:', '🧛‍♂️');
    retVal = this.replaceAll(retVal, ':vampire_woman:', '🧛‍♀️');
    retVal = this.replaceAll(retVal, ':merperson:', '🧜');
    retVal = this.replaceAll(retVal, ':merman:', '🧜‍♂️');
    retVal = this.replaceAll(retVal, ':mermaid:', '🧜‍♀️');
    retVal = this.replaceAll(retVal, ':elf:', '🧝');
    retVal = this.replaceAll(retVal, ':elf_man:', '🧝‍♂️');
    retVal = this.replaceAll(retVal, ':elf_woman:', '🧝‍♀️');
    retVal = this.replaceAll(retVal, ':genie:', '🧞');
    retVal = this.replaceAll(retVal, ':genie_man:', '🧞‍♂️');
    retVal = this.replaceAll(retVal, ':genie_woman:', '🧞‍♀️');
    retVal = this.replaceAll(retVal, ':zombie:', '🧟');
    retVal = this.replaceAll(retVal, ':zombie_man:', '🧟‍♂️');
    retVal = this.replaceAll(retVal, ':zombie_woman:', '🧟‍♀️');
    retVal = this.replaceAll(retVal, ':moneybag:', '💰');
    retVal = this.replaceAll(retVal, ':tropical_drink:', '🍹');
    retVal = this.replaceAll(retVal, ':sunny:', '☀️');
    retVal = this.replaceAll(retVal, ':beach_with_umbrella:', '🏖️');
    retVal = this.replaceAll(retVal, ':white_check_mark:', '✅');
    retVal = this.replaceAll(retVal, ':mortar_board:', '🎓');
    retVal = this.replaceAll(retVal, ':de:', '🇩🇪');
    retVal = this.replaceAll(retVal, ':clipboard:', '📋');
    retVal = this.replaceAll(retVal, ':bike:', '🚲');
    retVal = this.replaceAll(retVal, ':iphone:', '📱');
    retVal = this.replaceAll(retVal, ':identification_card:', '🎫');
    retVal = this.replaceAll(retVal, ':books:', '📚');
    retVal = this.replaceAll(retVal, ':gift:', '🎁');
    retVal = this.replaceAll(retVal, ':star2:', '🌟');
    retVal = this.replaceAll(retVal, ':moneybag:', '💰');
    retVal = this.replaceAll(retVal, ':\\+1:', '👍');
    retVal = this.replaceAll(retVal, ':palm_tree:', '🌴');
    retVal = this.replaceAll(retVal, ':card_index_dividers:', '🗂️');
    retVal = this.replaceAll(retVal, ':mag:', '🔍');
    retVal = this.replaceAll(retVal, ':package:', '📦');
    retVal = this.replaceAll(retVal, ':school_satchel:', '🎒');
    retVal = this.replaceAll(retVal, ':globe_with_meridians:', '🌐');
    retVal = this.replaceAll(retVal, ':hugging_face:', '🤗');
    retVal = this.replaceAll(retVal, ':face_with_monocle:', '🧐');
    retVal = this.replaceAll(retVal, ':incoming_envelope:', '📨');
    retVal = this.replaceAll(retVal, ':man-biking:', '🚴‍♂️');
    retVal = this.replaceAll(retVal, ':tada:', '🎉');
    retVal = this.replaceAll(retVal, ':date:', '📅');
    retVal = this.replaceAll(retVal, ':briefcase:', '💼');
    retVal = this.replaceAll(retVal, ':memo:', '📝');
    retVal = this.replaceAll(retVal, ':beers:', '🍻');
    retVal = this.replaceAll(retVal, ':earth_africa:', '🌍');
    retVal = this.replaceAll(retVal, ':man_and_woman_holding_hands:', '👫');
    retVal = this.replaceAll(retVal, ':female-office-worker:', '👩‍💼');
    retVal = this.replaceAll(retVal, ':building_construction:', '🏗️');
    retVal = this.replaceAll(retVal, ':speaking_head_in_silhouette:', '🗣️');
    retVal = this.replaceAll(retVal, ':jigsaw:', '🧩');
    retVal = this.replaceAll(retVal, ':sports_medal:', '🏅');
    retVal = this.replaceAll(retVal, ':shopping_bags:', '🛍️');
    retVal = this.replaceAll(retVal, ':knife_fork_plate:', '🍽️');
    retVal = this.replaceAll(retVal, ':dart:', '🎯');
    retVal = this.replaceAll(retVal, ':bulb:', '💡');
    retVal = this.replaceAll(retVal, ':shopping_bags:', '🛍️');
    retVal = this.replaceAll(retVal, ':knife_fork_plate:', '🍽️');
    retVal = this.replaceAll(retVal, ':first_quarter_moon_with_face:', '🌛');
    retVal = this.replaceAll(retVal, ':rainbow:', '🌈');
    retVal = this.replaceAll(retVal, ':dart:', '🎯');
    retVal = this.replaceAll(retVal, ':tv:', '📺');
    retVal = this.replaceAll(retVal, ':art:', '🎨');
    retVal = this.replaceAll(retVal, ':computer:', '💻');
    retVal = this.replaceAll(retVal, ':calling:', '📲');
    retVal = this.replaceAll(retVal, ':male-construction-worker:', '👷');
    retVal = this.replaceAll(retVal, ':zap:', '⚡');
    retVal = this.replaceAll(retVal, ':airplane_departure:', '🛫');
    retVal = this.replaceAll(retVal, ':electric_plug:', '🔌');
    retVal = this.replaceAll(retVal, ':clock3:', '🕒');
    retVal = this.replaceAll(retVal, ':man_mechanic:', '👨‍🔧');
    retVal = this.replaceAll(retVal, ':building:', '🏢');
    retVal = this.replaceAll(retVal, ':round_drawing_pin:', '📍');
    retVal = this.replaceAll(retVal, ':shirt:', '👕');
    retVal = this.replaceAll(retVal, ':last_quarter_moon:', '🌗');
    retVal = this.replaceAll(retVal, ':coffee:', '☕️');
    retVal = this.replaceAll(retVal, ':dice:', '🎲');
    retVal = this.replaceAll(retVal, ':label:', '🏷️');
    retVal = this.replaceAll(retVal, ':crescent_moon:', '🌙');
    retVal = this.replaceAll(retVal, ':herb:', '🌿');
    retVal = this.replaceAll(retVal, ':ant:', '🐜');
    retVal = this.replaceAll(retVal, ':woman_and_man_holding_hands:', '👫');
    retVal = this.replaceAll(retVal, ':palms_up_together:', '🤲');
    retVal = this.replaceAll(retVal, ':necktie:', '👔');
    retVal = this.replaceAll(retVal, ':de:', '🇩🇪');
    retVal = this.replaceAll(retVal, ':man_climbing:', '🧗‍♂️');
    retVal = this.replaceAll(retVal, ':bento:', '🍱');
    retVal = this.replaceAll(retVal, ':credit_card:', '💳');
    retVal = this.replaceAll(retVal, ':envelope:', '✉️');
    retVal = this.replaceAll(retVal, ':sun_with_face:', '☀️');
    retVal = this.replaceAll(retVal, ':luggage:', '🧳');
    retVal = this.replaceAll(retVal, ':clock4:', '🕓');
    retVal = this.replaceAll(retVal, ':woman-biking:', '🚴‍♀️');
    retVal = this.replaceAll(retVal, ':dollar:', '💵');
    retVal = this.replaceAll(retVal, ':female-mechanic:', '👩‍🔧');
    retVal = this.replaceAll(retVal, ':desktop_computer:', '🖥️');
    retVal = this.replaceAll(retVal, ':male-student:', '👨‍🎓');
    retVal = this.replaceAll(retVal, ':male-scientist:', '👨‍🔬');
    retVal = this.replaceAll(retVal, ':purse:', '👛');
    retVal = this.replaceAll(retVal, ':thinking_face:', '🤔');
    retVal = this.replaceAll(retVal, ':umbrella:', '☂️');
    retVal = this.replaceAll(retVal, ':male-mechanic:', '👨‍🔧');
    retVal = this.replaceAll(retVal, ':fork_and_knife_plate:', '🍽️');
    retVal = this.replaceAll(retVal, ':man_technologist:', '👨‍💻');
    retVal = this.replaceAll(retVal, ':meal:', '🍱');
    retVal = this.replaceAll(retVal, ':bookmark_tabs:', '📑');
    retVal = this.replaceAll(retVal, ':burrito:', '🌯');
    retVal = this.replaceAll(retVal, ':woman-lifting-weights::skin-tone-2:', '🏋🏼‍♀️');
    retVal = this.replaceAll(retVal, ':woman-cartwheeling:', '🤸‍♀️');
    retVal = this.replaceAll(retVal, ':knife_fork_plate:', '🍽️');
    retVal = this.replaceAll(retVal, ':sandwich:', '🥪');
    retVal = this.replaceAll(retVal, ':desert_island:', '🏝️');
    retVal = this.replaceAll(retVal, ':earth_asia:', '🌏');
    retVal = this.replaceAll(retVal, ':scroll:', '📜');
    retVal = this.replaceAll(retVal, ':seedling:', '🌱');
    retVal = this.replaceAll(retVal, ':bookmark:', '🔖');
    retVal = this.replaceAll(retVal, ':page_facing_up:', '📄');
    retVal = this.replaceAll(retVal, ':mailbox_with_mail:', '📬');
    retVal = this.replaceAll(retVal, ':spiral_calendar_pad:', '🗓️');

    return retVal;
  }

  public replaceAll(targetString: string, oldSubstr: string, newSubstr: string): string {
    if (!targetString) return '';

    return targetString.replace(new RegExp(oldSubstr, 'g'), newSubstr);
  }
}
