import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AssessmentApi, CompensationApi, JobApi, RecruitmentProcessApi } from '@web/shared/data-access/model';
import { PaginateResultWithPageCount, PaginateWithTotalCount } from '@web/shared/util/paginate';
import { JobItem } from '@web/web/applicant/domain/job/ui/job-item';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { TextVariantPipe } from '@web/web/shared/util/pipes';
import { Observable, filter, take, tap } from 'rxjs';

export interface JobListState {
  paginatedJobs: PaginateResultWithPageCount<JobItem>;
  successPopUpOpened: boolean;
  selectedJob?: JobItem;
  searchTerm: string;
  isFirstSearch: boolean;
  listOfPopularSearchItems: string[];
  assessment?: AssessmentApi.Assessment;
}

@Injectable({
  providedIn: 'root',
})
export class JobListViewModel extends ComponentStore<JobListState> {
  public vm$: Observable<JobListState> = this.select(state => ({
    paginatedJobs: state.paginatedJobs,
    successPopUpOpened: state.successPopUpOpened,
    selectedJob: state.selectedJob,
    searchTerm: state.searchTerm,
    isFirstSearch: state.isFirstSearch,
    listOfPopularSearchItems: state.listOfPopularSearchItems,
    assessment: state.assessment,
  })).pipe(filter(obj => !!obj));

  constructor(
    private readonly jobApiService: JobApiService,
    private readonly jobApplicationApiService: JobApplicationApiService,
    private readonly alertService: AlertService,
    private readonly textVariantPipe: TextVariantPipe,
  ) {
    super({
      paginatedJobs: {
        data: [],
        pageSize: 25,
        pageCount: 1,
        currentPage: 1,
        total: 0,
      },
      successPopUpOpened: false,
      selectedJob: undefined,
      searchTerm: '',
      isFirstSearch: true,
      listOfPopularSearchItems: [
        'domain.jobs.feature.search.popular-searches.items.electrician',
        'domain.jobs.feature.search.popular-searches.items.mechanic',
        'domain.jobs.feature.search.popular-searches.items.warehouse-worker',
        'domain.jobs.feature.search.popular-searches.items.technician',
        'domain.jobs.feature.search.popular-searches.items.service',
        'domain.jobs.feature.search.popular-searches.items.cleaning',
        'domain.jobs.feature.search.popular-searches.items.LKW-driver',
        'domain.jobs.feature.search.popular-searches.items.sales',
        'domain.jobs.feature.search.popular-searches.items.delivery-driver',
        'domain.jobs.feature.search.popular-searches.items.production',
      ],
    });
  }

  public destroyData(): void {
    this.patchState({
      paginatedJobs: {
        data: [],
        pageSize: 25,
        pageCount: 1,
        currentPage: 1,
        total: 0,
      },
    });
  }

  public getMany(page: number = this.get().paginatedJobs.currentPage): void {
    const { paginatedJobs, searchTerm } = this.get();

    this.patchState({ paginatedJobs: { ...paginatedJobs, data: [] } });

    this.jobApiService
      .getAllPaginated(paginatedJobs.pageSize ?? 25, page, searchTerm)
      .pipe(
        take(1),
        tap((paginatedResult: PaginateResultWithPageCount<JobApi.Job>) => {
          const paginatedJobs: PaginateWithTotalCount = {
            pageSize: paginatedResult.pageSize,
            pageCount: paginatedResult.pageCount,
            currentPage: paginatedResult.currentPage,
            total: paginatedResult.total,
          };

          this.patchState({ paginatedJobs: { ...paginatedJobs, data: this.mapToViewJobs(paginatedResult.data) } });
        }),
      )
      .subscribe();
  }

  public search(searchTerm: string): void {
    const { paginatedJobs } = this.get();

    this.patchState({
      paginatedJobs: { ...paginatedJobs, data: [], currentPage: 1 },
      searchTerm,
      isFirstSearch: false,
    });

    this.getMany();
  }

  private mapToViewJobs(jobs: JobApi.Job[]): JobItem[] {
    return jobs.map(job => ({
      id: job.id,
      titleVariants: job.title,
      descriptionVariants: job.jobBranding?.description ?? [],
      employer: job.company.name,
      image: job.company.avatarUrl,
      jobHeaderImgUrl: (job.jobBranding?.images.find(image => image.isHeader)?.previewUrl as string) ?? undefined,
      tags: [
        {
          label: this.textVariantPipe.transform(job.jobType!.textVariants),
          value: 'jobType',
        },
        {
          icon: 'map-pin',
          label: `${job.address.postalCode}, ${job.address.city}`,
          value: job.address.city,
        },
        {
          icon: 'briefcase',
          label: job.company.name,
          value: job.company.name,
        },
      ],
      compensations: this.mapCompensations(job.compensations),
      employmentTypes: this.mapEmploymentTypes(job.employmentTypes),
      jobTypes: job.jobType,
    }));
  }

  private mapEmploymentTypes(employmentTypes: JobApi.EmploymentType[]): string[] {
    return employmentTypes.map(employmentType => JobApi.employmentTypeToHumanReadableValue(employmentType));
  }

  private mapCompensations(compensations: CompensationApi.CompensationViewItem[]): string[] {
    const sortedCompensations = this.sortCompensationViewItemsByType(compensations);

    return sortedCompensations.map(compensation => {
      const currencyStr = CompensationApi.convertCurrencyToHumanReadableString(compensation.currency);
      const bonusStr = compensation.compensationType === CompensationApi.CompensationType.BONUS ? ' bonus' : '';

      return `${currencyStr}${compensation.amountFrom}/${CompensationApi.convertIntervalToHumanReadableString(
        compensation.interval,
      )}${bonusStr}`;
    });
  }

  private sortCompensationViewItemsByType(
    compensations: CompensationApi.CompensationViewItem[],
  ): CompensationApi.CompensationViewItem[] {
    return compensations.sort((a, b) =>
      a.compensationType === CompensationApi.CompensationType.SALARY
        ? -1
        : b.compensationType === CompensationApi.CompensationType.SALARY
          ? 1
          : 0,
    );
  }

  public applyForJobInternally(jobId: string, job: JobItem): void {
    this.patchState({ selectedJob: job });

    this.jobApplicationApiService
      .applyForJobInternally(jobId)
      .pipe(
        take(1),
        tap(application => {
          if (
            application.currentStep.recruitmentProcessType === RecruitmentProcessApi.RecruitmentStepType.ASSESSMENT &&
            application.currentStep.assessment
          ) {
            this.patchState({ assessment: application.currentStep.assessment });
          }
          this.getMany();
          // TODO: add translation string for this message
          this.alertService.success('Successfully applied for job');
          this.toggleSuccessPopUp();
        }),
      )
      .subscribe();
  }

  public toggleSuccessPopUp(): void {
    const successPopUpOpened = this.get().successPopUpOpened;

    this.patchState({ successPopUpOpened: !successPopUpOpened });
  }

  public setFirstSearch(): void {
    this.patchState({ isFirstSearch: true });
  }
}
