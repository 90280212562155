import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingViewModel {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading$.asObservable();
  public static cancelAllRequests$ = new Subject<void>();

  public setLoading(isLoading: boolean): void {
    this._isLoading$.next(isLoading);
  }

  public get isLoading(): boolean {
    return this._isLoading$.getValue();
  }

  public static cancelAllRequests(): void {
    LoadingViewModel.cancelAllRequests$.next(); // Signal cancellation to all observables
    LoadingViewModel.cancelAllRequests$.complete(); // Complete the subject (this is optional but good practice)
    LoadingViewModel.cancelAllRequests$ = new Subject<void>(); // Re
  }
}
