import { SafeUrl } from '@angular/platform-browser';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DropdownOption } from '@web/web/shared/ui/dropdown';
import { BrandingApi } from './branding-api.namespace';
import { HereMapsApi } from './here-maps.namespace';
import { JobApi } from './job-api.namespace';
import { ProfessionalFieldApi } from './professional-fields-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace CompanyApi {
  export enum WebSocketChannelType {
    INITIALIZE = 'INITIALIZE',
    STATISTICS = 'STATISTICS',
  }

  export enum CompanyDetailTab {
    GENERAL = 'GENERAL',
    JOBS = 'JOBS',
    BRANDING = 'BRANDING',
    USERS = 'USERS',
    INTEGRATIONS = 'INTEGRATIONS',
    APPLICATIONS = 'APPLICATIONS',
  }

  export interface Company {
    id: string;
    name: string;
    address: HereMapsApi.AddressData;
    website: string;
    phone: string;
    email: string;
    isActive: boolean;
    companySize: CompanySize;
    companySizeText?: string;
    textVariants?: TextVariantApi.TextVariant[];
    avatarUrl?: string;
    bannerUrl?: string;
    professionalField?: ProfessionalFieldApi.ProfessionalField;
    videoLink?: string;
    images?: BrandingApi.Image[];
    jobs?: JobApi.ProfileDetailJob[];
    isDeletable?: boolean;
    companySource?: CompanySource;
  }

  export interface CompanyProfileView {
    id: string;
    address: HereMapsApi.AddressData;
    website: string;
    phone: string;
    email: string;
    companySize: string;
    name: string;
    about?: TextVariantApi.TextVariant[];
    avatarUrl?: string;
    bannerUrl?: string;
    professionalField: ProfessionalFieldApi.ProfessionalField;
    videoLink?: string;
    images: SafeUrl[];
    jobs: JobApi.ProfileDetailJobView[];
  }

  export enum CompanySource {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    PERSONIO = 'PERSONIO',
  }

  export enum CompanySize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
  }

  export function companySizeToNumbers(companySize: CompanySize): string {
    switch (companySize) {
      case CompanyApi.CompanySize.SMALL:
        return '1 - 50';
      case CompanyApi.CompanySize.MEDIUM:
        return '50 - 250';
      case CompanyApi.CompanySize.LARGE:
        return '250+';
    }
  }

  export interface CreateCompany {
    name: string;
    address: HereMapsApi.AddressData;
    website?: string;
    phone?: string;
    email?: string;
    textVariants?: TextVariantApi.CreateTextVariant[];
    companySize: CompanySize;
    professionalField: ProfessionalFieldApi.ProfessionalField;
    videoLink?: string;
    isActive: boolean;
    companySource: CompanySource;
  }

  export interface UpdateCompany {
    id: string;
    name?: string;
    address?: HereMapsApi.AddressData;
    website?: string;
    phone?: string;
    email?: string;
    textVariants?: TextVariantApi.UpdateTextVariant[] | TextVariantApi.CreateTextVariant[];
    companySize?: CompanySize;
    professionalField?: ProfessionalFieldApi.CreateProfessionalField | ProfessionalFieldApi.UpdateProfessionalField;
    isActive?: boolean;
    isActiveDropdown: DropdownOption;
    videoLink?: string;
    companySource?: CompanySource;
    companySourceDropdown: DropdownOption;
  }

  export interface CompanyProfile extends Company {
    previewImages: SafeUrl[];
  }

  export interface Statistics {
    activeJobListingCount: number;
    newApplicationsCount: number;
    noFitCount: number;
    hiresCount: number;
    applicationsCount: number;
    activeApplicationsCount: number;
  }

  export interface General {
    name: string;
    phone?: string;
    isActive: boolean;
    size: CompanySize;
    source: CompanySource;
    address: HereMapsApi.AddressData;
    professionalField: ProfessionalFieldApi.ProfessionalField;
    email: string;
    website: string;
    avatarUrl: string;
    bannerUrl: string;
  }

  export interface Branding {
    videoLink?: string;
    description?: TextVariantApi.TextVariant[];
    images?: BrandingApi.Image[];
  }

  export interface UpdateBranding {
    description?: TextVariantApi.TextVariant[];
    videoLink?: string;
    newImages?: { isHeader: boolean; name: string; file: File }[];
    idOfImagesToDelete?: string[];
  }

  export interface InfoCard {
    name: string;
    address: HereMapsApi.AddressData;
    professionalField: ProfessionalFieldApi.ProfessionalField;
    size: CompanySize;
    isActive: boolean;
    avatarUrl: string;
    bannerUrl: string;
  }
}
