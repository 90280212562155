import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Pipe({
  name: 'requiredField',
  standalone: true,
})
export class RequiredFieldPipe implements PipeTransform {
  public transform(control: AbstractControl | null): boolean {
    if (!control) return false;

    return control.hasValidator(Validators.required);
  }
}
