import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicantApi } from '@web/shared/data-access/model';
import { AuthGuard } from '@web/web/core/auth/data-access';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { ApplicantAuthViewModel } from '../viewmodel/applicant-auth.viewmodel';
import { ApplicantLoginViewModel } from '../viewmodel/applicant-login.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class ApplicantAuthGuard extends AuthGuard<ApplicantApi.ApplicantLoginResponseDto> {
  constructor(
    applicantAuthViewModel: ApplicantAuthViewModel,
    applicantLoginViewModel: ApplicantLoginViewModel,
    router: Router,
  ) {
    super(applicantAuthViewModel, applicantLoginViewModel, router);
  }

  public override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Object.keys(route.queryParams).includes('jobId')) {
      if (route.queryParams['jobId'].startsWith('j')) {
        return of(true);
      }

      this.router.navigate([`/external-application/${route.queryParams['jobId']}`], {
        queryParams: { ...route.queryParams, jobId: undefined },
        queryParamsHandling: 'merge',
      });

      return of(true);
    } else {
      this.loginViewModel.setRedirectUrl(state.url, route.queryParams);

      return this.authViewModel.validateAuthAsObs().pipe(
        take(1),
        switchMap(() =>
          this.authViewModel.isAuthenticated$.pipe(
            take(1),
            tap(isSuccess => {
              if (isSuccess) {
                return;
              }

              this.authViewModel.logout(route.queryParams);
            }),
          ),
        ),
      );
    }
  }
}
