import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { Observable, catchError, takeUntil, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingViewModel: LoadingViewModel) {}

  public intercept<T, U>(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<U>> {
    this.loadingViewModel.setLoading(true);
    this.totalRequests++;

    return next.handle(request).pipe(
      takeUntil(LoadingViewModel.cancelAllRequests$),
      finalize(() => {
        this.totalRequests--;

        if (this.totalRequests === 0) {
          this.loadingViewModel.setLoading(false);
        }
      }),
      catchError(err => {
        return throwError(err);
      }),
    );
  }
}
