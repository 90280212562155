export namespace IntegrationApi {
  export interface Integration {
    id: string;
    connectionId: string;
    connectionName?: ConnectionName;
    name: string;
    logoUrl?: string;
  }

  export interface KomboIntegration extends Integration {
    category: KomboIntegrationCategory;
  }

  export enum KomboIntegrationCategory {
    HRIS = 'HRIS',
    ATS = 'ATS',
    ASSESSMENT = 'ASSESSMENT',
  }

  export interface KomboJobDropdown {
    id: string;
    name: string;
  }

  export enum ConnectionName {
    KOMBO = 'Kombo',
  }
}
