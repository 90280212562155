import { Injectable } from '@angular/core';
import { NavBottomItem } from '@web/web/shared/ui/nav-bottom-item';

@Injectable({
  providedIn: 'root',
})
export class NavBottomService {
  public isHidden = false;

  public navItems: NavBottomItem[] = [
    {
      url: 'dashboard',
      title: 'shared.route.dashboard',
      icon: 'home',
    },
    {
      url: 'jobs',
      title: 'shared.route.jobs',
      icon: 'search',
    },
    {
      url: 'applications',
      title: 'shared.route.applications',
      icon: 'briefcase',
    },
  ];

  public toggleVisibility(hide?: boolean): void {
    if (hide) {
      this.isHidden = hide;

      return;
    } else this.isHidden = !this.isHidden;
  }

  public hide(): void {
    this.isHidden = true;
  }

  public show(): void {
    this.isHidden = false;
  }
}
