<div class="verification-container">
  <h1>{{ 'core.auth.feature.verify-account.title' | transloco }}</h1>

  <img src="/assets/image/email_verified.png" alt="@" />

  <p>{{ 'core.auth.feature.verify-account.text' | transloco }}</p>

  <wh-s-button
    routerLink="/"
    variant="primary"
    [label]="'core.auth.feature.verify-account.continue' | transloco"
    [disabled]="isContinueButtonDisabled"
  ></wh-s-button>
</div>
