// eslint-disable-next-line @nx/enforce-module-boundaries
import { HereMapsApi } from '@web/shared/data-access/model';

export function formatAddress(addressData: HereMapsApi.AddressData | undefined, short?: boolean): string {
  if (!addressData) return '';

  const addressParts: string[] = [];
  const notApplicable = 'Not applicable';

  const pushIfValid = (value: string | undefined, condition: string): void => {
    if (value && value !== condition) {
      addressParts.push(value);
    }
  };

  if (short) {
    if (addressData.postalCode && addressData.postalCode !== notApplicable) {
      pushIfValid(addressData.postalCode, notApplicable);
      pushIfValid(addressData.city, notApplicable);
    } else if (addressData.city && addressData.city !== notApplicable) {
      pushIfValid(addressData.city, notApplicable);
      pushIfValid(addressData.state, notApplicable);
    } else if (addressData.state && addressData.state !== notApplicable) {
      pushIfValid(addressData.state, notApplicable);
      pushIfValid(addressData.countryCode, notApplicable);
    } else {
      pushIfValid(addressData.countryCode, notApplicable);
    }
  } else {
    if (addressData.city && addressData.city !== notApplicable) {
      pushIfValid(addressData.houseNumber, notApplicable);
      pushIfValid(addressData.street, notApplicable);
      pushIfValid(addressData.postalCode, notApplicable);
      addressParts.push(addressData.city);
    } else {
      pushIfValid(addressData.district, notApplicable);
      pushIfValid(addressData.county, notApplicable);
      pushIfValid(addressData.state, notApplicable);
      if (addressData.countryCode && addressData.countryCode !== notApplicable) {
        addressParts.push(addressData.countryCode);
      }
    }
  }

  return addressParts.join(', ');
}
