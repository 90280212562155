// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
import { HereMapsApi } from './here-maps.namespace';
import { JobTypeApi } from './job-type-api.namespace';

export namespace ApplicantApprenticeshipApi {
  export interface Apprenticeship {
    id?: string;
    degree: DegreeApprenticeship;
    address?: HereMapsApi.AddressData;
    name?: string;
    title?: string;
    fromDate?: Date;
    toDate?: Date;
    jobType?: JobTypeApi.JobType;
    jobTypeId?: string;
  }

  export enum DegreeApprenticeship {
    VOCATIONAL_TRAINING_CURRENT = 'VOCATIONAL_TRAINING_CURRENT',
    VOCATIONAL_TRAINING_COMPLETED = 'VOCATIONAL_TRAINING_COMPLETED',
    TECHNICIAN_MASTER_CRAFTSMAN = 'TECHNICIAN_MASTER_CRAFTSMAN',
  }
  export enum DegreeApprenticeshipRegister {
    VOCATIONAL_TRAINING_CURRENT = 'VOCATIONAL_TRAINING_CURRENT',
    VOCATIONAL_TRAINING_COMPLETED = 'VOCATIONAL_TRAINING_COMPLETED',
    TECHNICIAN_MASTER_CRAFTSMAN = 'TECHNICIAN_MASTER_CRAFTSMAN',
    NO = 'NO',
  }

  export function getApprenticeshipDegreeTranslationString(degree: DegreeApprenticeship): string {
    switch (degree) {
      case ApplicantApprenticeshipApi.DegreeApprenticeship.VOCATIONAL_TRAINING_CURRENT:
        return 'shared.ui.apprenticeships.VOCATIONAL_TRAINING_CURRENT';
      case ApplicantApprenticeshipApi.DegreeApprenticeship.TECHNICIAN_MASTER_CRAFTSMAN:
        return 'shared.ui.apprenticeships.TECHNICIAN_MASTER_CRAFTSMAN';
      case ApplicantApprenticeshipApi.DegreeApprenticeship.VOCATIONAL_TRAINING_COMPLETED:
        return 'shared.ui.apprenticeships.VOCATIONAL_TRAINING_COMPLETED';
    }
  }

  export interface DegreeItem {
    degree: DegreeApprenticeshipRegister;
    icon: FeatherIconType;
  }
}
