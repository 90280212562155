import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthApi, OtpApi } from '@web/shared/data-access/model';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AuthApiService<T> {
  public abstract readonly afterLogoutRoute: string;

  protected constructor(
    protected readonly url: string,
    protected readonly httpClient: HttpClient,
  ) {}

  public login(loginDto: AuthApi.LoginDto): Observable<T> {
    return this.httpClient.post<T>(`${this.url}/authenticate`, loginDto, {
      withCredentials: true,
    });
  }

  public sendOtp(phoneNumber: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/send-otp`, { phoneNumber });
  }

  public sendOtpPhoneNrChange(applicantId: string): Observable<string> {
    return this.httpClient.put(`${this.url}/send-otp-phone-change/${applicantId}`, {}, { responseType: 'text' });
  }

  public loginWithPhone(loginPhoneDto: AuthApi.LoginWithPhoneDto): Observable<T> {
    return this.httpClient.post<T>(`${this.url}/authenticate-phone`, loginPhoneDto, {
      withCredentials: true,
    });
  }

  public logout(): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/logout`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  public signInViaRefreshToken(tokenPair: AuthApi.TokenPair): Observable<T> {
    return this.httpClient.post<T>(`${this.url}/refresh-token`, tokenPair, {
      withCredentials: true,
    });
  }

  public validateToken(tokenPair: AuthApi.TokenPair): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/validate-token`, tokenPair, {
      withCredentials: true,
    });
  }

  public verifyUser(id: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/verify/${id}`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  public sendResetPasswordEmail(email: string): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/email-reset-password`, { email });
  }

  public resetPassword(userId: string, email: string, token: string, password: string): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.httpClient.put<void>(
      `${this.url}/${userId}/reset-password`,
      { email, password, token },
      {
        headers,
      },
    );
  }

  public createPending(phoneNumber: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/create-pending`, { phoneNumber });
  }

  public sendUserInputOtp(otp: OtpApi.OTPValidityCheck): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/check-otp`, otp);
  }
}
