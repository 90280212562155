/**
 * Keep this and feather-icon.type.ts file in sync
 */

// Select icons which are to be used, don't import all of them to reduce bundle size
import {
  AlertOctagon,
  AlertTriangle,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Award,
  Bell,
  BookOpen,
  Briefcase,
  Calendar,
  Camera,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Compass,
  Copy,
  Cpu,
  CreditCard,
  DollarSign,
  Download,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  File,
  Filter,
  Folder,
  Gift,
  Globe,
  Grid,
  Heart,
  HelpCircle,
  Home,
  Image,
  Info,
  Layers,
  List,
  Loader,
  LogOut,
  Mail,
  MapPin,
  Menu,
  MessageSquare,
  Phone,
  Plus,
  PlusCircle,
  RefreshCw,
  Search,
  Settings,
  Shuffle,
  Slash,
  Trash2,
  TrendingUp,
  Truck,
  User,
  Users,
  X,
  XOctagon,
  Zap,
} from 'angular-feather/icons';

export const featherIcons = {
  Facebook,
  LogOut,
  Settings,
  User,
  Users,
  Bell,
  Grid,
  Trash2,
  Edit,
  Edit2,
  Edit3,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  Loader,
  X,
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Briefcase,
  Archive,
  Layers,
  Check,
  Slash,
  CheckCircle,
  Info,
  MapPin,
  Clock,
  CreditCard,
  PlusCircle,
  Plus,
  ArrowLeft,
  Globe,
  File,
  Compass,
  BookOpen,
  Truck,
  Calendar,
  Gift,
  Home,
  HelpCircle,
  EyeOff,
  Eye,
  XOctagon,
  AlertOctagon,
  Menu,
  DollarSign,
  Copy,
  Heart,
  Folder,
  Zap,
  Mail,
  Phone,
  Camera,
  Image,
  Award,
  ExternalLink,
  Shuffle,
  MessageSquare,
  Search,
  Download,
  TrendingUp,
  Filter,
  AlertTriangle,
  RefreshCw,
  List,
  Cpu,
};
