import { Injectable } from '@angular/core';
import { ApplicantAuthViewModel } from '@web/web/applicant/core/auth/data-access';
import { AvatarService } from '@web/web/shared/data-access/avatar';
import { NavTopItem } from '@web/web/shared/ui/nav-top-item';
import { BehaviorSubject, filter, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavTopService {
  public navItems: NavTopItem[] = [
    {
      title: 'shared.route.dashboard',
      url: 'dashboard',
    },
    {
      title: 'shared.route.jobs',
      url: 'jobs',
    },
    {
      title: 'shared.route.applications',
      url: 'applications',
    },
  ];

  public logoSrc = '/assets/image/workerhero_logo.svg';
  public avatarSrc = '';
  public username = '';
  public isHidden = false;
  public showBoxShadow$ = new BehaviorSubject(true);
  public hidden$ = new BehaviorSubject(false);

  constructor(
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    public readonly avatarService: AvatarService,
  ) {
    this.applicantAuthViewModel.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        tap(() => {
          const loginData = this.applicantAuthViewModel.getLoginData();

          if (!loginData) {
            return;
          }

          this.avatarService.setAvatarUrl(<string>loginData.avatarUrl);

          this.username = `${loginData.firstName} ${loginData.lastName.charAt(0).toUpperCase()}.`;
          this.avatarSrc = loginData.avatarUrl ?? '';
        }),
      )
      .subscribe();

    this.avatarService.vm$.pipe(tap(vm => (this.avatarSrc = <string>vm.avatarUrl))).subscribe();
  }

  public toggleNavTopVisibility(hide?: boolean): void {
    if (hide) {
      this.isHidden = hide;
    } else this.isHidden = !this.isHidden;

    this.hidden$.next(this.isHidden);
  }

  public hide(): void {
    this.isHidden = true;
    this.hidden$.next(this.isHidden);
  }

  public show(): void {
    this.isHidden = false;
    this.hidden$.next(this.isHidden);
  }
}
