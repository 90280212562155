import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApplicantApi,
  ApplicantApprenticeshipApi,
  ApplicantEducationApi,
  ApplicantLanguageApi,
  ApplicantTrackingSystemApi,
  ApplicantWorkExperienceApi,
  AuthApi,
  CertificateApi,
  CompensationApi,
  DriverLicenseApi,
  TranslocoApi,
} from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth/auth-api.service';
import { BASE_URL_TOKEN } from './config/api-token';
import ChangePassword = AuthApi.ChangePassword;

@Injectable({
  providedIn: 'root',
})
export class ApplicantApiService extends AuthApiService<ApplicantApi.ApplicantLoginResponseDto> {
  public readonly afterLogoutRoute = '/auth';

  private readonly legalInfoUrl: string;

  constructor(httpClient: HttpClient, @Inject(BASE_URL_TOKEN) baseUrl: string) {
    super(`${baseUrl}/applicants`, httpClient);

    this.legalInfoUrl = `${baseUrl}/legal-info`;
  }

  public addDriverLicense(
    applicantId: string,
    driverLicense: DriverLicenseApi.DriverLicenseApplicant,
  ): Observable<DriverLicenseApi.DriverLicenseApplicant> {
    return this.httpClient.post<DriverLicenseApi.DriverLicenseApplicant>(
      `${this.url}/driver-license/${applicantId}`,
      driverLicense,
    );
  }

  public addDriverLicenses(
    applicantId: string,
    driverLicenses: DriverLicenseApi.DriverLicenseApplicant[],
  ): Observable<DriverLicenseApi.DriverLicenseApplicant[]> {
    return this.httpClient.post<DriverLicenseApi.DriverLicenseApplicant[]>(
      `${this.url}/driver-license/many/${applicantId}`,
      {
        license: driverLicenses,
      },
    );
  }

  public addCertificate(
    applicantId: string,
    certificate: CertificateApi.Certificate,
  ): Observable<CertificateApi.Certificate> {
    return this.httpClient.post<CertificateApi.Certificate>(`${this.url}/certificate/${applicantId}`, certificate);
  }

  public addCertificates(
    applicantId: string,
    certificates: CertificateApi.Certificate[],
  ): Observable<CertificateApi.Certificate[]> {
    return this.httpClient.post<CertificateApi.Certificate[]>(
      `${this.url}/certificate/many/${applicantId}`,
      certificates,
    );
  }

  /*
    Applicant profile methods
  */

  public getApplicantProfile(): Observable<ApplicantApi.ApplicantProfile> {
    return this.httpClient.get<ApplicantApi.ApplicantProfile>(`${this.url}/profile`);
  }

  public getApplicantProfileById(id: string): Observable<ApplicantApi.ApplicantProfile> {
    return this.httpClient.get<ApplicantApi.ApplicantProfile>(`${this.url}/profile/${id}`);
  }

  public getApplicantForATS(applicantId: string): Observable<ApplicantApi.ApplicantProfile> {
    return this.httpClient.get<ApplicantApi.ApplicantProfile>(`${this.url}/${applicantId}/for-ats`);
  }

  public getApplicantAiSummary(applicantId: string): Observable<string> {
    return this.httpClient.get(`${this.url}/${applicantId}/ai-summary`, { responseType: 'text' });
  }

  public saveBanner(applicantId: string, file: File): Observable<string> {
    const formData = new FormData();

    formData.append('file', file);

    return this.httpClient.post(`${this.url}/banner/${applicantId}`, formData, {
      responseType: 'text',
    });
  }

  public saveAvatar(applicantId: string, imageFile: File): Observable<string> {
    const formData = new FormData();

    formData.append('file', imageFile);

    return this.httpClient.post(`${this.url}/avatar/${applicantId}`, formData, {
      responseType: 'text',
    });
  }

  public createWorkExperience(
    applicantId: string,
    createWorkExperience: ApplicantWorkExperienceApi.WorkExperience,
  ): Observable<ApplicantWorkExperienceApi.WorkExperience> {
    return this.httpClient.post<ApplicantWorkExperienceApi.WorkExperience>(
      `${this.url}/work-experience/${applicantId}`,
      createWorkExperience,
    );
  }

  public updateWorkExperience(
    applicantId: string,
    updateWorkExperience: ApplicantWorkExperienceApi.WorkExperience,
  ): Observable<ApplicantWorkExperienceApi.WorkExperience> {
    return this.httpClient.put<ApplicantWorkExperienceApi.WorkExperience>(
      `${this.url}/work-experience`,
      updateWorkExperience,
    );
  }

  public deleteWorkExperience(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/work-experience/${id}`);
  }

  public getEducationById(id: string): Observable<ApplicantEducationApi.Education> {
    return this.httpClient.get<ApplicantEducationApi.Education>(`${this.url}/education/${id}`);
  }

  public createEducation(
    applicantId: string,
    academic: ApplicantEducationApi.Education,
  ): Observable<ApplicantEducationApi.Education> {
    return this.httpClient.post<ApplicantEducationApi.Education>(`${this.url}/education/${applicantId}`, academic);
  }

  public updateEducation(
    applicantId: string,
    updateEducationAcademic: ApplicantEducationApi.Education,
  ): Observable<ApplicantEducationApi.Education> {
    return this.httpClient.put<ApplicantEducationApi.Education>(
      `${this.url}/education/${applicantId}`,
      updateEducationAcademic,
    );
  }

  public deleteEducation(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/education/${id}`);
  }

  public getApprenticeshipById(id: string): Observable<ApplicantApprenticeshipApi.Apprenticeship> {
    return this.httpClient.get<ApplicantApprenticeshipApi.Apprenticeship>(`${this.url}/apprenticeship/${id}`);
  }

  public createApprenticeship(
    applicantId: string,
    apprenticeship: ApplicantApprenticeshipApi.Apprenticeship,
  ): Observable<ApplicantApprenticeshipApi.Apprenticeship> {
    return this.httpClient.post<ApplicantApprenticeshipApi.Apprenticeship>(
      `${this.url}/apprenticeship/${applicantId}`,
      apprenticeship,
    );
  }

  public updateApprenticeship(
    updateEducationApprenticeship: ApplicantApprenticeshipApi.Apprenticeship,
  ): Observable<ApplicantApprenticeshipApi.Apprenticeship> {
    return this.httpClient.put<ApplicantApprenticeshipApi.Apprenticeship>(
      `${this.url}/apprenticeship`,
      updateEducationApprenticeship,
    );
  }

  public deleteApprenticeship(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/apprenticeship/${id}`);
  }

  public updatePersonalInfo(
    applicantId: string,
    updatePersonalInfo: Partial<ApplicantApi.PersonalInformationV2>,
  ): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/personal-information/${applicantId}`, updatePersonalInfo);
  }

  public createAccount(applicant: ApplicantApi.RegisterApplicant): Observable<ApplicantApi.ApplicantLoginResponseDto> {
    return this.httpClient.post<ApplicantApi.ApplicantLoginResponseDto>(`${this.url}/create-account`, applicant, {
      withCredentials: true,
    });
  }

  public createAccountFromAdmin(
    applicant: ApplicantApi.RegisterApplicant,
  ): Observable<ApplicantApi.ApplicantLoginResponseDto> {
    return this.httpClient.post<ApplicantApi.ApplicantLoginResponseDto>(`${this.url}/admin/create-account`, applicant);
  }

  public deleteCertificate(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/certificate/${id}`);
  }

  public updateDriverLicense(
    updateDriverLicense: DriverLicenseApi.DriverLicenseApplicant,
  ): Observable<DriverLicenseApi.DriverLicenseApplicant> {
    return this.httpClient.put<DriverLicenseApi.DriverLicenseApplicant>(
      `${this.url}/driver-license/${updateDriverLicense.id}`,
      updateDriverLicense,
    );
  }

  public deleteDriverLicense(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/driver-license/${id}`);
  }

  public deleteLanguage(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/language/${id}`);
  }

  public updateLanguage(
    applicantId: string,
    language: ApplicantLanguageApi.Language,
  ): Observable<ApplicantLanguageApi.Language> {
    return this.httpClient.put<ApplicantLanguageApi.Language>(`${this.url}/language/${applicantId}`, language);
  }

  public createLanguage(
    applicantId: string,
    createLanguage: ApplicantLanguageApi.Language,
  ): Observable<ApplicantLanguageApi.Language> {
    return this.httpClient.post<ApplicantLanguageApi.Language>(`${this.url}/language/${applicantId}`, createLanguage);
  }

  public uploadDocument(
    applicantId: string,
    front: File,
    documentType: ApplicantApi.DocumentType,
    back?: File,
  ): Observable<void> {
    const formData = new FormData();

    formData.append('files', front);
    formData.append('documentType', documentType);

    if (back) {
      formData.append('files', back);
    }

    return this.httpClient.post<void>(`${this.url}/document/${applicantId}`, formData);
  }

  public getMany(pageSize: number, currentPage: number): Observable<PaginateResult<ApplicantApi.Applicant>> {
    return this.httpClient.get<PaginateResult<ApplicantApi.Applicant>>(`${this.url}/many`, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public getSearchResults(
    pageSize: number,
    page: number,
    searchTerm: string,
  ): Observable<PaginateResult<ApplicantApi.Applicant>> {
    return this.httpClient.get<PaginateResult<ApplicantApi.Applicant>>(`${this.url}/search-many`, {
      params: new HttpParams().set('currentPage', page).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public delete(applicantId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${applicantId}`);
  }

  public deleteDocument(documentId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/document/${documentId}`);
  }

  public addAdditionalRegisterData(data: Partial<ApplicantApi.Applicant>, applicantId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/additional-register-data/${applicantId}`, {
      additionalRegisterData: data,
    });
  }

  public updateSalaryPreference(
    applicantId: string,
    compensation: CompensationApi.Compensation,
  ): Observable<CompensationApi.Compensation> {
    return this.httpClient.put<CompensationApi.Compensation>(`${this.url}/compensation/${applicantId}`, compensation);
  }

  // noinspection TypeScriptValidateTypes
  // eslint-disable-next-line
  public downloadCvAsPDF(applicantId: string): Observable<any> {
    const headers = new HttpHeaders();

    // noinspection TypeScriptValidateTypes
    return this.httpClient.get(`${this.url}/${applicantId}/download-cv`, {
      headers,
      responseType: 'blob',
    });
  }

  public exportApplicationsAsCsv(
    companyId: string,
    filters: ApplicantTrackingSystemApi.FilterData,
  ): Observable<string> {
    return this.httpClient.post(`${this.url}/export-csv?companyId=${companyId}`, filters, { responseType: 'text' });
  }

  public downloadDocument(
    applicantId: string,
    downloadDocumentAssetDto: ApplicantApi.DownloadDocumentItemAsset,
    // eslint-disable-next-line
  ): Observable<any> {
    return this.httpClient.post(`${this.url}/${applicantId}/download-asset`, downloadDocumentAssetDto, {
      responseType: 'blob',
    });
  }

  public syncToZoho(applicantId: string): Observable<void> {
    return this.httpClient.get<void>(`${this.url}/zoho-sync/${applicantId}`);
  }

  public changePassword(applicantId: string | undefined, changePassword: ChangePassword): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/change-password/${applicantId}`, changePassword);
  }

  public changeEmail(applicantId: string, newEmail: string): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/change-email/${applicantId}`, { email: newEmail });
  }

  public changePhoneNumber(applicantId: string, phoneNumber: string): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/change-phone/${applicantId}`, { phoneNumber });
  }

  public deactivateAccount(applicantId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/deactivate-account/${applicantId}`);
  }

  public updateCommunicationLanguage(
    applicantId: string,
    communicationLanguage: TranslocoApi.Locale,
  ): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/communication-language/${applicantId}`, { communicationLanguage });
  }

  public getCommunicationLanguage(applicantId: string): Observable<{ communicationLanguage: TranslocoApi.Locale }> {
    return this.httpClient.get<{ communicationLanguage: TranslocoApi.Locale }>(
      `${this.url}/communication-language/${applicantId}`,
    );
  }
}
