import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }

  /**
   * Loads root scope & child scope in app
   * Use this impl if you want to load nested i18n files
   * @param lang
   *
   public getTranslation(lang: string): Observable<Translation> {
    const baseTranslationPath = `/assets/i18n/${lang}.json`;
    const sharedTranslationPath = `./assets/i18n/shared/${lang}.json`;

    const baseTranslationPath$ = this.http.get<Translation>(baseTranslationPath);
    const sharedTranslationPath$ = this.http.get<Translation>(sharedTranslationPath);

    return forkJoin([baseTranslationPath$, sharedTranslationPath$]).pipe(
      map(([baseTranslationPath, sharedTranslationPath]) => {
        return {
          ...baseTranslationPath,
          ...sharedTranslationPath,
        };
      }),
    );
   }
   */
}
