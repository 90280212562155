import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HereMapsApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { formatAddress } from '@web/web/shared/util/function';

@Pipe({
  name: 'formatAddress',
  standalone: true,
})
export class FormatAddressPipe implements PipeTransform {
  public transform(addressData: HereMapsApi.AddressData | undefined, short?: boolean): string {
    return formatAddress(addressData, short);
  }
}
