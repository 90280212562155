import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'linkSanitizer',
  standalone: true,
})
export class LinkSanitizerPipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {}

  public transform(value: unknown, ...args: unknown[]): SafeUrl {
    if (typeof value !== 'string') {
      throw new Error('Link should be of type string');
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
