import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { SpinnerComponent } from '@web/web/shared/ui/spinner';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-text-area',
  standalone: true,
  imports: [CommonModule, FeatherModule, FontAwesomeModule, SpinnerComponent, FormsModule],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild(forwardRef(() => TextAreaComponent)) public ref!: TextAreaComponent;

  /**
   * Variants of the textarea
   */
  @Input()
  public variant: 'default' | 'success' | 'danger' = 'default';

  /**
   * Error label to be displayed in case danger variant is active
   */
  @Input()
  public errorLabel?: string = '';

  /**
   * How large should the input be?
   */
  @Input()
  public size: 'md' | 'lg' = 'md';

  /**
   * What placeholder should be displayed next to the textarea?
   */
  @Input()
  public placeholder = '';

  /**
   * What hint should be displayed next to the textarea?
   */
  @Input()
  public hint = '';

  /**
   * Whether the input is disabled or not
   */
  @Input()
  public disabled = false;

  /**
   * optional icon to display before  textarea
   */
  @Input()
  public icon?: IconProp;

  /**
   * optional icon to display after textarea
   */
  @Input()
  public iconAfter?: IconProp;

  /**
   * The label of textarea
   *
   * @required
   */
  @Input()
  public label = 'Email';

  @Input()
  public required = false;

  @Input()
  public isInvalid = false;

  @Input()
  public showLoading = false;

  @Input()
  public initialValue: string;

  /**
   * Optional click handler icon before
   */
  @Output()
  public iconClick = new EventEmitter<Event>();

  /**
   * Emits current textarea value on input change
   */
  @Output()
  public inputChange = new EventEmitter<string>();

  @Input()
  public id: number | string;

  /**
   * Optional click handler icon after
   */
  @Output()
  public iconClickEmitter = new EventEmitter<Event>();

  public valid: boolean;

  public _val = ''; // this is the updated value that the class accesses
  public passwordEyeIconOn = faEye;
  public passwordEyeIconOff = faEyeSlash;
  public passwordOn = false;
  public isFocused = false;

  public set value(val: string) {
    // this value is updated by programmatic changes
    if (val !== undefined && this._val !== val) {
      this._val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  public get value(): string {
    return this._val;
  }

  public get containerClasses(): string[] {
    return [
      `wh-s-text-area--container--${this.variant}`,
      `wh-s-text-area--container--${this.disabled ? 'disabled' : ''}`,
    ];
  }

  /**
   * Adds md class to the host element
   */
  @HostBinding('class.md')
  public get isMidSize(): boolean {
    return this.size === 'md';
  }

  /**
   * Adds lg class to the host element
   */
  @HostBinding('class.lg')
  public get isLargeSize(): boolean {
    return this.size === 'lg';
  }

  /**
   * Adds default class to the host element
   */
  @HostBinding('class.default')
  public get isDefaultVariant(): boolean {
    return this.variant === 'default';
  }

  /**
   * Adds success class to the host element
   */
  @HostBinding('class.success')
  public get isSuccessVariant(): boolean {
    return this.variant === 'success';
  }

  /**
   * Adds danger class to the host element
   */
  @HostBinding('class.danger')
  public get isDangerVariant(): boolean {
    return this.variant === 'danger';
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.disabled;
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.loading')
  public get isLoading(): boolean {
    return this.loadingViewModel.isLoading && this.showLoading;
  }

  constructor(public readonly loadingViewModel: LoadingViewModel) {}

  public ngOnInit(): void {
    if (this.required) this.valid = false;
  }

  public ngAfterViewInit(): void {
    if (this.initialValue) this.value = this.initialValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unused-vars
  public onChange = (val: string) => {
    if (this.required) {
      this.valid = !!this.value;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/explicit-function-return-type
  public onTouch = (val: string) => {};

  // this method sets the value programmatically
  public writeValue(val: string): void {
    this.value = val;
    this._val = val;
  }

  // upon UI element value changes, this method gets triggered
  // eslint-disable-next-line @typescript-eslint/ban-types
  public registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  // eslint-disable-next-line @typescript-eslint/ban-types
  public registerOnTouched(fn: () => {}): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
