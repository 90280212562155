import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

interface AvatarState {
  avatarUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AvatarService extends ComponentStore<AvatarState> {
  public readonly vm$: Observable<AvatarState> = this.select(state => ({
    avatarUrl: state.avatarUrl,
  }));

  constructor() {
    super({
      avatarUrl: '',
    });
  }

  public setAvatarUrl(avatarUrl: string): void {
    this.patchState({ avatarUrl });
  }
}
