import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthApi } from '@web/shared/data-access/model';
import { Observable } from 'rxjs';
import { CLIENT_INJECTION_TOKEN } from '../client.token';
import { AuthViewModel } from '../viewmodel/auth.viewmodel';

@Injectable()
export abstract class AuthInterceptor<T> implements HttpInterceptor {
  protected constructor(
    protected readonly router: Router,
    protected readonly authViewModel: AuthViewModel<T>,
    @Inject(CLIENT_INJECTION_TOKEN) protected readonly client: AuthApi.Client,
    protected readonly translocoService: TranslocoService,
  ) {}

  //  FIXME: Restructure
  /**
   *
   * @param req
   * @param next
   */
  public intercept<T, U>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<U>> {
    // don't add authorization headers if "skipAuthorization" header is present
    if (req.headers.get('skipAuthorization')) {
      const cloned = req.clone({
        headers: new HttpHeaders(),
      });

      return next.handle(cloned);
    }

    const loginData = this.authViewModel.getLoginData() as any;

    if (!loginData) {
      return next.handle(
        req.clone({
          withCredentials: true,
          headers: req.headers
            .set('Client', this.client.toString())
            .set('CurrentLocale', this.translocoService.getActiveLang()),
        }),
      );
    }

    return next.handle(
      req.clone({
        withCredentials: true,
        headers: req.headers
          .set('Client', this.client.toString())
          .set('CurrentLocale', this.translocoService.getActiveLang())
          .set('Authorization', `Bearer ${loginData.accessToken}`),
      }),
    );
  }
}
