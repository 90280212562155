import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state(
    'open',
    style({
      opacity: 1,
    }),
  ),
  state(
    'close',
    style({
      opacity: 0,
    }),
  ),
  transition('open => closed', [animate('0.3s ease-out')]),
  transition('closed => open', [animate('0.2s ease')]),
]);
