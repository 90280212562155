import { createReducer, on } from '@ngrx/store';
import { AuthApi } from '@web/shared/data-access/model';
import * as GlobalActions from './global.action';

export const GLOBAL_FEATURE_KEY = 'GLOBAL_FEATURE_KEY';

export type GlobalState = {
  role: AuthApi.AUTH_ROLE | null;
  activeEntityId: string | null;
};

const initialGlobalState: GlobalState = {
  role: null,
  activeEntityId: null,
};

export const globalReducer = createReducer(
  initialGlobalState,
  on(
    GlobalActions.setRole,
    (state, { authRole }): GlobalState => ({
      ...state,
      role: authRole,
    }),
  ),
  on(
    GlobalActions.setActiveEntityId,
    (state, { entityId }): GlobalState => ({
      ...state,
      activeEntityId: entityId,
    }),
  ),
  on(
    GlobalActions.resetGlobalStoreData,
    (state): GlobalState => ({
      role: null,
      activeEntityId: null,
    }),
  ),
);
