import { OccupationalGroupApi } from './occupational-group-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace OccupationalSubgroupApi {
  export enum Status {
    PRIVATE = 'Private',
    PUBLIC = 'Public',
  }

  export interface OccupationalSubgroup {
    id: string;
    textVariants: TextVariantApi.TextVariant[];
    occupationalGroup: OccupationalGroupApi.OccupationalGroup;
    status: Status;
  }

  export interface CreateOccupationalSubgroup {
    occupationalGroupId: string;
    textVariants: TextVariantApi.CreateTextVariant[];
    status: Status;
  }

  export interface UpdateOccupationalSubgroup {
    id: string;
    occupationalGroupId: string;
    textVariants: TextVariantApi.UpdateTextVariant[];
    status: Status;
  }
}
