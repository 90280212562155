import { ProfessionalFieldApi } from './professional-fields-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace OccupationalGroupApi {
  export enum Status {
    PRIVATE = 'Private',
    PUBLIC = 'Public',
  }

  export interface OccupationalGroup {
    id: string;
    textVariants: TextVariantApi.TextVariant[];
    professionalField: ProfessionalFieldApi.ProfessionalField;
    status: Status;
  }

  export interface CreateOccupationalGroup {
    professionalFieldId: string;
    textVariants: TextVariantApi.CreateTextVariant[];
    status: Status;
  }

  export interface UpdateOccupationalGroup {
    id: string;
    professionalFieldId: string;
    textVariants: TextVariantApi.UpdateTextVariant[];
    status: Status;
  }
}
