import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longWordTrim',
  standalone: true,
})
export class LongWordTrimPipe implements PipeTransform {
  public transform(value: string, wordLength?: number): string {
    if (!value) {
      return '';
    }

    if (value.length > 30) return value.substring(0, wordLength ?? 18) + '...';
    else return value;
  }
}
