import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { SpinnerComponent } from '@web/web/shared/ui/spinner';
import { FeatherModule } from 'angular-feather';
import { InputComponent } from './input.component';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [InputComponent, SafeHtmlPipe],
  imports: [CommonModule, FontAwesomeModule, FormsModule, FeatherModule, SpinnerComponent, WebCoreI18nModule],
  exports: [InputComponent, SafeHtmlPipe],
})
export class InputModule {}
