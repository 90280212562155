import { Injectable } from '@angular/core';
import { ApplicantApi } from '@web/shared/data-access/model';
import { RefreshTokenInterceptor } from '@web/web/core/auth/data-access';
import { ApplicantApiService } from '@web/web/shared/data-access/api';
import { ApplicantAuthViewModel } from '../viewmodel/applicant-auth.viewmodel';

@Injectable()
export class ApplicantRefreshTokenInterceptor extends RefreshTokenInterceptor<ApplicantApi.ApplicantLoginResponseDto> {
  constructor(applicantApiService: ApplicantApiService, applicantAuthViewModel: ApplicantAuthViewModel) {
    super(applicantApiService, applicantAuthViewModel);
  }
}
