import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantApi, JobApplicationApi } from '@web/shared/data-access/model';
import { AuthViewModel } from '@web/web/core/auth/data-access';
import { ApplicantApiService } from '@web/web/shared/data-access/api';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';
import { ApplicantLocalStorageService } from '../storage/applicant-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicantAuthViewModel extends AuthViewModel<ApplicantApi.ApplicantLoginResponseDto> {
  public readonly LOGOUT_ROUTE = 'auth';
  private readonly createAccountDataKey = 'create_account_data_key';
  public readonly externalApplicationDataKey = 'external_application_data_key';

  constructor(
    applicantLocalStorageService: ApplicantLocalStorageService,
    router: Router,
    protected applicantApiService: ApplicantApiService,
    private readonly utmLocalStorageService: UtmLocalStorageService,
  ) {
    super(applicantLocalStorageService, router, applicantApiService);
  }

  public storeAccountData(applicant: Partial<ApplicantApi.Applicant>): void {
    let data;

    if (localStorage.getItem(this.createAccountDataKey ?? '')) {
      data = JSON.parse(localStorage.getItem(this.createAccountDataKey ?? '') ?? '');
    }

    applicant.utm = this.utmLocalStorageService.getUtmData();
    applicant.zapier = this.getExternalApplicationData() && this.getExternalApplicationData()?.zapier;
    applicant = { ...data, ...applicant };
    localStorage.setItem(this.createAccountDataKey, JSON.stringify(applicant));
  }

  public removeAccountData(): void {
    localStorage.removeItem(this.createAccountDataKey);
  }

  public getExternalApplicationData(): JobApplicationApi.JobApplication | undefined {
    if (localStorage.getItem(this.externalApplicationDataKey)) {
      return JSON.parse(localStorage.getItem(this.externalApplicationDataKey) ?? '');
    }

    return undefined;
  }

  public setExternalApplicationData(jobApplication: JobApplicationApi.JobApplication): void {
    localStorage.setItem(this.externalApplicationDataKey, JSON.stringify(jobApplication));
  }

  public removeExternalApplicationData(): void {
    localStorage.removeItem(this.externalApplicationDataKey);
  }

  public processRegistration(loginData: ApplicantApi.ApplicantLoginResponseDto): void {
    this.authLocalStorageService.storeLoginData(loginData);
    this.isAuthenticated$.next(true);
  }
}
