import { Injectable } from '@angular/core';
import { ApplicantApi } from '@web/shared/data-access/model';
import { VerificationViewModel } from '@web/web/core/auth/data-access';
import { ApplicantApiService } from '@web/web/shared/data-access/api';

@Injectable({
  providedIn: 'root',
})
export class ApplicantVerificationViewModel extends VerificationViewModel<ApplicantApi.ApplicantLoginResponseDto> {
  constructor(applicantApiService: ApplicantApiService) {
    super(applicantApiService);
  }
}
