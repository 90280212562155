/* eslint-disable */
import { LoadingViewModel } from '../loading.viewmodel';

/**
 *
 * Decorator used to automatically unsubscribe from all the ongoing
 * HTTP requests. Example of usage:
 *  ```
 *  @AutoUnsubscribe()
 *  export class ListComponent...
 *  ```
 * Adding this decorator to the class will ensure that all the active requests are
 * cancelled upon class' destruction. No further action besides adding the decorator is needed.
 */
export function AutoUnsubscribe() {
  return function (constructor: any) {
    const originalOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      LoadingViewModel.cancelAllRequests();

      if (originalOnDestroy && typeof originalOnDestroy === 'function') {
        originalOnDestroy.apply(this, arguments);
      }
    };
  };
}
