import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LanguageSwitchService } from '@web/web/shared/data-access/language';

@Pipe({
  name: 'textVariant',
  standalone: true,
  pure: false,
})
export class TextVariantPipe implements PipeTransform {
  constructor(private readonly languageSwitchService: LanguageSwitchService) {}

  public transform(value?: TextVariantApi.TextVariant[]): string {
    if (!value) {
      return '';
    }

    // find text variant for currently set language
    const selectedLanguage = this.languageSwitchService.getSelectedLanguage();
    const textVariant = value.find(variant => variant.locale === selectedLanguage && variant.value);
    if (textVariant) {
      return textVariant.value;
    }

    // give higher priority to German if currently set language is not available
    const germanTextVariant = value.find(variant => variant.locale === TranslocoApi.Locale.DE && variant.value);
    if (germanTextVariant) {
      return germanTextVariant.value;
    }

    // return non-empty value
    return value.find(variant => variant.value)?.value ?? '';
  }
}
