import { Injectable } from '@angular/core';
import { JobrapidoApiService } from '@web/web/shared/data-access/api';
import { catchError, of } from 'rxjs';

/**
 * https://brands.recruitrapido.com/ServiceJR/Doc/API_FeedbackLoop.pdf
 */
@Injectable({ providedIn: 'root' })
export class JobrapidoService {
  private readonly clientCode = '001Mf00000G5B0A';
  public clickId: string;

  constructor(private readonly jobrapidoApiService: JobrapidoApiService) {}

  public sendJobrapidoClickValidityEvent(): void {
    if (!this.clickId) {
      return;
    }

    this.jobrapidoApiService
      .sendJobrapidoFeedbackEvent({
        clickId: this.clickId,
        clientCode: this.clientCode,
        conversionType: '1',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendJobrapidoApplicationStartEvent(): void {
    if (!this.clickId) {
      return;
    }

    this.jobrapidoApiService
      .sendJobrapidoFeedbackEvent({
        clickId: this.clickId,
        clientCode: this.clientCode,
        conversionType: '4',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendJobrapidoApplicationCompleteEvent(): void {
    if (!this.clickId) {
      return;
    }

    this.jobrapidoApiService
      .sendJobrapidoFeedbackEvent({
        clickId: this.clickId,
        clientCode: this.clientCode,
        conversionType: '5',
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }
}
