import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantApi } from '@web/shared/data-access/model';
import { LoggedInGuard } from '@web/web/core/auth/data-access';
import { ApplicantAuthViewModel } from '../viewmodel/applicant-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class ApplicantLoggedInGuard extends LoggedInGuard<ApplicantApi.ApplicantLoginResponseDto> {
  protected constructor(applicantAuthViewModel: ApplicantAuthViewModel, router: Router) {
    super(applicantAuthViewModel, router, 'dashboard');
  }
}
