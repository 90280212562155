import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { PanelTopModule } from '@web/web/shared/ui/panel-top';

@Component({
  selector: 'wh-s-not-found',
  standalone: true,
  imports: [CommonModule, PanelTopModule, WebCoreI18nModule],
  template: `
    <div class="panel-top">
      <img class="workerhero-logo-lg" src="/assets/image/workerhero_logo.png" alt="" />
    </div>

    <div class="content-wrapper">
      <div class="message-container">
        <h5 class="wh-header-lg">{{ 'shared.ui.not-found.message' | transloco }}</h5>

        <img
          class="animated-gif"
          src="/assets/image/animation_404.gif"
          alt="{{ 'shared.ui.not-found.alt-image-message' | transloco }}"
        />

        <p class="navigate-text wh-paragraph-md">
          {{ 'shared.ui.not-found.navigate-back-1' | transloco }}
          <span class="navigate-link" (click)="navigateHome()">{{
            'shared.ui.not-found.navigate-back-2' | transloco
          }}</span>
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        display: block;
        width: 100%;
        height: 100%;

        @include mx.flex-center-y();
        justify-content: flex-start;

        & > .panel-top {
          display: block;
          width: calc(var(--panel-top-width) - 5.5rem);
          height: calc(var(--panel-top-height) - var(--side-nav-default-border-thickness));
          border-bottom: var(--side-nav-default-border);
          padding-left: 5.5rem;

          @include mx.flex-center-x();
          justify-content: flex-start;

          & > .workerhero-logo-lg {
            width: 9rem;
            user-select: none;
            transition: all 0.3s ease;
          }
        }

        & > .content-wrapper {
          height: calc(100% - var(--panel-top-height) - var(--side-nav-default-border-thickness));
          padding: 1rem;

          @include mx.flex-center-x();

          & > .message-container {
            text-align: center;

            @include mx.flex-center-y();

            @media (max-width: 280px) {
              display: none;
            }

            & > .wh-header-lg {
              font-size: 35px;
            }

            & > .animated-gif {
              margin: 1.5rem 0;
            }

            & .navigate-link {
              cursor: pointer;
              color: dodgerblue;
              text-decoration: underline;
            }
          }
        }
      }
    `,
  ],
})
export class NotFoundComponent {
  private readonly router = inject(Router);

  public navigateHome(): void {
    this.router.navigate(['/']);
  }
}
