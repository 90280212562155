import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarWrapperComponent } from '@web/web/shared/ui/avatar-wrapper';
import { LanguageSwitchModule } from '@web/web/shared/ui/language-switch';
import { LanguageSwitchNewComponent } from '@web/web/shared/ui/language-switch-new';
import { FeatherModule } from 'angular-feather';
import { RoleChipComponent } from 'web/shared/ui/role-chip';
import { PanelTopComponent } from './panel-top.component';

@NgModule({
  imports: [
    CommonModule,
    FeatherModule,
    LanguageSwitchModule,
    LanguageSwitchNewComponent,
    AvatarWrapperComponent,
    RoleChipComponent,
  ],
  declarations: [PanelTopComponent],
  exports: [PanelTopComponent],
})
export class PanelTopModule {}
