import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { enterLeaveSlow } from '@web/web/shared/ui/animation';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-main-page',
  standalone: true,
  imports: [CommonModule, FeatherModule],
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [enterLeaveSlow],
})
export class MainPageComponent implements AfterViewInit {
  @Input()
  public title = '';

  @Input()
  public canStepBack = false;

  @Input()
  @HostBinding('class.overflow')
  public canOverflow = false;

  @Output()
  public stepBack: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('@enterLeaveSlow')
  public get enterLeaveSlow(): boolean {
    return true;
  }

  @ViewChild('mainPageTitle') public readonly pageTitle: ElementRef;

  public ngAfterViewInit(): void {
    this.pageTitle.nativeElement.scrollIntoView({ block: 'start', behavior: 'instant' });
  }
}
