import { Observable, takeWhile, tap, timer } from 'rxjs';

/**
 * Creates countdown timer with RXJS
 *
 * Function can also receive a callback on every tick
 * Observable will self-destruct after timer has been exceeded
 *
 * @param counter
 * @param callbackOnTick
 */
export function createCountdownTimer(counter: number, callbackOnTick?: (counter: number) => void): Observable<number> {
  return timer(1000, 1000) // Initial delay 1 second and interval countdown also 1 second
    .pipe(
      takeWhile(() => counter > 0),
      tap(() => {
        counter--;

        if (!callbackOnTick) {
          return;
        }

        callbackOnTick(counter);
      }),
    );
}
