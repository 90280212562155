import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
import { Subject, debounceTime, takeWhile } from 'rxjs';

@Component({
  selector: 'wh-s-button',
  template: `
    <button type="button" (click)="clickAction.emit($event)" [disabled]="disabled" [ngClass]="classes">
      <div class="before-icon" *ngIf="showIconContainer">
        <i-feather *ngIf="icon" [name]="icon"></i-feather>
      </div>

      <img *ngIf="iconPath" [src]="iconPath" alt="icon" />
      <img *ngIf="image" [src]="image" alt="icon" class="wh-s-button-img" />
      <span class="wh-s-button-label">{{ label }}</span>
      <i-feather *ngIf="iconAfter" [name]="iconAfter"></i-feather>

      <div class="loading-wrapper" *ngIf="showLoadingContainer">
        <wh-s-spinner *ngIf="(loadingViewModel.isLoading$ | async) === true && hasLoadingSpinner"></wh-s-spinner>
      </div>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnDestroy {
  /**
   * Variants of the button
   */
  @Input()
  public variant: 'zero' | 'primary' | 'secondary' | 'success' | 'danger' = 'primary';

  /**
   * optional icon to display
   */
  @Input()
  public image?: string;

  /**
   * optional icon to display
   */
  @Input()
  public icon?: FeatherIconType;

  /**
   * optional icon (path) from repo to display
   */
  @Input()
  public iconPath?: string;

  /**
   * optional icon to display after text
   */
  @Input()
  public iconAfter?: FeatherIconType;

  /**
   * How large should the button be?
   */
  @Input()
  public size: 'sm' | 'md' | 'lg' = 'md';

  /**
   * Whether the button style should be filled or not
   */
  @Input()
  public filled = false;

  /**
   * Whether the button style should be filled or not
   */
  @Input()
  public disabled: boolean | null | undefined = false;

  /**
   * Whether the button has a drop shadow or not
   */
  @Input()
  public elevated = true;

  /**
   * Button contents
   *
   * @required
   */
  @Input()
  public label = 'Button';

  @Input()
  public hasLoadingSpinner = false;

  @Input()
  public showIconContainer = true;

  @Input()
  public showLoadingContainer = true;

  /**
   * Optional click handler
   */
  @Output()
  public clickAction = new EventEmitter<Event>();

  private ctaClickDebounce$ = new Subject<Event>();
  private isComponentAlive = false;

  public get classes(): string[] {
    return [
      'wh-s-button',
      'wh-s-flex-wrapper',
      this.disabled ? 'disabled' : '',
      `wh-s-button--${this.size}`,
      `wh-s-button--${this.variant}${this.filled ? '--filled' : ''}`,
      `wh-s-button--${this.variant}${this.elevated ? '--shadow' : ''}`,
    ];
  }

  constructor(public readonly loadingViewModel: LoadingViewModel) {}

  public ngOnInit(): void {
    this.isComponentAlive = true;

    this.ctaClickDebounce$
      .pipe(
        takeWhile(() => this.isComponentAlive),
        debounceTime(150),
      )
      .subscribe(event => this.clickAction.emit(event));
  }

  public ngOnDestroy(): void {
    this.isComponentAlive = false;
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return !!this.disabled;
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.loading')
  public get isLoading(): boolean {
    return this.loadingViewModel.isLoading && this.hasLoadingSpinner;
  }
}
