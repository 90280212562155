import { JobTypeApi } from './job-type-api.namespace';

export namespace EducationApi {
  export interface Education {
    id?: string;
    educationType: EducationType;
    required?: boolean;
    jobType?: JobTypeApi.JobType;
  }

  export interface CreateUpdateEducation {
    id?: string;
    educationType: EducationType;
    required?: boolean;
    jobTypeId?: string;
  }

  export enum EducationType {
    ACADEMIC = 'ACADEMIC',
    APPRENTICESHIP = 'APPRENTICESHIP',
  }
}
