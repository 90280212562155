import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SpinnerComponent } from '@web/web/shared/ui/spinner';
import { FeatherModule } from 'angular-feather';
import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, FeatherModule, SpinnerComponent],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonModule {}
