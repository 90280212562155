import { RecruitmentProcessApi } from './recruitment-process-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace AssessmentApi {
  import CreateTextVariant = TextVariantApi.CreateTextVariant;

  export interface Assessment {
    id: string;
    title?: string;
    createdAt?: Date;
    jobId?: string;
    recruitmentProcessStep?: RecruitmentProcessApi.RecruitmentStep;
    recruitmentProcessType?: RecruitmentProcessApi.RecruitmentStepType;
    positionIndex?: number;
    recruitmentProcessStepId?: string;
    questions: AssessmentQuestion[];
    //  TODO: REMOVE ONCE EMPLOYER HAS BEEN REFACTORED, THE SAME EFFECT CAN BE OBTAINED VIA !!assessment.score
    hasPassScore?: boolean;
    score?: AssessmentScore;
    isHidden?: boolean;
    syncActive?: boolean;
  }

  export interface AssessmentDetails {
    id: string;
    title: string;
    questions: AssessmentQuestionDetails[];
    jobTitleVariants?: TextVariantApi.TextVariant[];
    hasPassScore: boolean;
    score?: AssessmentScore;
    createdAt?: Date;
    isEdited?: boolean;
  }

  export interface AssessmentQuestionDetails {
    id: string;
    nameVariants: TextVariantApi.TextVariant[];
    hasMultipleAnswers: boolean;
    answers: AssessmentQuestionAnswerDetails[];
  }

  export interface AssessmentQuestionAnswerDetails {
    id: string;
    answerVariants: TextVariantApi.TextVariant[];
    score?: number;
  }

  export interface AssessmentScore {
    id?: string;
    score: number;
    failCaseStep?: RecruitmentProcessApi.RecruitmentStep;
    passCaseStep?: RecruitmentProcessApi.RecruitmentStep;
  }

  export interface AssessmentQuestion {
    id: string;
    assessmentId?: string;
    name?: string;
    hasMultipleAnswers: boolean;
    answers: AssessmentQuestionAnswer[];
    nameTextVariants?: CreateTextVariant[];
  }

  export interface AssessmentQuestionAnswer {
    id?: string;
    questionId?: string;
    answer?: string;
    score: number;
    answerNameTextVariants?: CreateTextVariant[];
  }

  export interface ApplicantAssessment {
    assessmentId: string;
    answers: ApplicantAssessmentAnswer[];
  }

  export interface ApplicantAssessmentAnswer {
    questionId: string;
    answerIds: string[];
  }

  export interface ApplicantAnswersFormatted {
    questionVariants: TextVariantApi.TextVariant[];
    answerVariants: TextVariantApi.TextVariant[];
  }

  export interface ApplicantScore {
    score: number;
    moveToStep: RecruitmentProcessApi.RecruitmentStep;
  }

  export interface ApplicantParticipation extends AssessmentDetails {
    applicantId: string;
    assessmentId: string;
    enteredPhaseAt: Date;
    completedAssessmentAt: Date;
    enableAnotherChance: boolean;
    applicantAnswers: ApplicantAnswersFormatted[];
    applicantScore?: ApplicantScore;
    status: AssessmentParticipationStatus;
  }

  export interface ApplicantParticipationWrapper {
    currentData: ApplicantParticipation[];
    originalData: ApplicantParticipation[];
  }

  export enum AssessmentParticipationStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    PASSED = 'PASSED',
  }
}
