import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { SpinnerComponent } from '@web/web/shared/ui/spinner';
import { FeatherModule } from 'angular-feather';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FeatherModule,
    FontAwesomeModule,
    SpinnerComponent,
    WebCoreI18nModule,
  ],
  exports: [DropdownComponent],
})
export class DropdownModule {}
