import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class JoobleApiService {
  private readonly url: string;
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/job-feeds/jooble/postback`;
  }

  public sendJoobleFeedbackEvent(joobleFeedbackEvent: {
    utmAway: string;
    actionType: 'InitPage' | 'ApplyStart' | 'ApplyFinish' | 'Subscription' | 'Registration';
  }): Observable<void> {
    return this.httpClient.post<void>(this.url, joobleFeedbackEvent);
  }
}
