// The following line is needed because jest-presets doesn't support namespaces
// tslint:disable-next-line: no-namespace
import { AuthApi } from './auth-api.namespace';

export namespace AdminApi {
  export interface RegisterAdmin {
    firstName: string;
    lastName: string;
    email: string;
    role?: Role;
    password: string;
  }

  export interface UpdateAdmin {
    id: string;
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
    password?: string;
    role?: Role;
  }

  export interface Admin {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
  }

  export enum Role {
    DEVELOPER = 'DEVELOPER',
    SUPER_ADMIN = 'SUPER_ADMIN',
    MARKETING = 'MARKETING',
    APPLICANT_SERVICE_AGENT = 'APPLICANT_SERVICE_AGENT',
    CUSTOMER_SUCCESS_MANAGER = 'CUSTOMER_SUCCESS_MANAGER',
  }

  export interface AdminLoginResponseDto extends AuthApi.BaseLoginResponseDto {
    role: Role;
  }
}
